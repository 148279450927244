import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, forkJoin, of, throwError, concat, merge, Observable } from "rxjs";
import { SiteLocation, SiteLocationStore } from "../models/site-locator.model";
import { SiteLocatorService } from "./site-locator.service";
import { catchError, map, share, shareReplay, switchMap, toArray, tap } from "rxjs/operators";


@Injectable({
    providedIn: 'root'
})
export class MapStoreService {

     siteDetails = new BehaviorSubject([]);

     siteDetails$ = this.siteDetails.asObservable();

     deatils:any;

    limit = 10000;

    constructor(private siteLocatorSevice: SiteLocatorService) { }

    private readonly _store = new BehaviorSubject({ filterData: true,filterOptions:[], data: [] } as SiteLocationStore);

    public readonly store$ = this._store.asObservable();

    private readonly _selectedLocation = new BehaviorSubject(null as SiteLocation);

    public readonly selectedLocation$ = this._selectedLocation.asObservable();

    private markersSubject = new BehaviorSubject<any[]>([]);
    markers$ = this.markersSubject.asObservable();

    patchStore(data: Partial<SiteLocationStore>): void {
        this._store.next({
            ...this._store.value,
            ...data
        });
    }

    patchStoreSelectedLocation(data: Partial<SiteLocation>): void {
        if (data) {
            this._selectedLocation.next({
                ...this._selectedLocation.value,
                ...data
            });
        } else {
            this._selectedLocation.next(null);
        }
    }

    stieAMSDetailsItems$ = this.siteLocatorSevice.getSiteDetails(this.limit).pipe(
        map(firstApiRes => {
            let offsetArray = [];
            const countIndex = Math.ceil(firstApiRes[0].totalRecords / this.limit);
            for (let i = 1; i < countIndex; i++) {
                offsetArray.push(i);
            }
            return { firstAPIResposnse: firstApiRes, offsetArrayAPIs: (firstApiRes[0].totalRecords  > this.limit ? offsetArray : [0]) };
        }),
        switchMap((resObject) => {
            let wishStateObservables = resObject.offsetArrayAPIs.filter(offset => offset > 0).map(offset => this.siteLocatorSevice.getSiteDetails(this.limit, offset).pipe(catchError((err) => { return throwError(err) })));
            if (wishStateObservables.length > 0) {
                return forkJoin(wishStateObservables).pipe(
                    map(res => {
                        return [...res, resObject.firstAPIResposnse];
                    },
                        catchError((err) => { return of([]) })
                    ));
            } else {
                return [[resObject.firstAPIResposnse]];
            }

        }),
        
        map((siteLocations: SiteLocation[][]) => {
            //this.deatils =  '789009766'
            this.siteDetails.next(siteLocations)
            //this.saveData(siteLocations);
            return siteLocations;
        }),
        catchError((err) => { return of([]) })
    );

    saveData(siteLocations){
        this.deatils = '90853345777'
        this.siteDetails.next(siteLocations)
    }

    stiePolesDetailsItems$ = this.siteLocatorSevice.getPolesDetails(this.limit).pipe(
        map(firstApiRes => {
            let offsetArray = [];
            const countIndex = Math.ceil(firstApiRes[0].totalRecords / this.limit);
            for (let i = 1; i < countIndex; i++) {
                offsetArray.push(i);
            }
            return { firstAPIResposnse: firstApiRes, offsetArrayAPIs: (firstApiRes[0].totalRecords  > this.limit ? offsetArray : [0]) };
        }),
        switchMap((resObject) => {
            let wishStateObservables = resObject.offsetArrayAPIs.filter(offset => offset > 0).map(offset => this.siteLocatorSevice.getPolesDetails(this.limit, offset).pipe(catchError(() => of(null))));
            if (wishStateObservables.length > 0) {


                const chunks: Observable<any>[][] = [];
                for (let i = 0; i < wishStateObservables.length; i++) {
                    const index = i % 6;
                    if (!chunks[index]) chunks[index] = [];
                    chunks[index].push(wishStateObservables[i]);
                }

                return forkJoin(chunks.map(chunk =>
                    concat(...chunk).pipe(
                      toArray(),
                      map((res) => {
                        return  res;
                      }),
                      catchError((err) => { 
                          return of([]) })
                      )
                  )).pipe(
                    map((res) => {
                        const newArray = [];
                        (res || []).forEach((row) => {
                            newArray.push(...row);
                        })
                        return  [...newArray, resObject.firstAPIResposnse];
                      })
                  )

            }else{
                return [[resObject.firstAPIResposnse]];
            }
        }),
        map(siteLocations => {
            return siteLocations;
        }),
        catchError((err) => {  return of([]) })
    );

    stieExchangesDetailsItems$ = this.siteLocatorSevice.getExchangeDetails(this.limit).pipe(
        map(firstApiRes => {
            let offsetArray = [];
            const countIndex = Math.ceil(firstApiRes[0].totalRecords / this.limit);
            for (let i = 1; i < countIndex; i++) {
                offsetArray.push(i);
            }
            return { firstAPIResposnse: firstApiRes, offsetArrayAPIs: (firstApiRes[0].totalRecords  > this.limit ? offsetArray : [0]) };
        }),
        switchMap((resObject) => {
            let wishStateObservables = resObject.offsetArrayAPIs.filter(offset => offset > 0).map(offset => this.siteLocatorSevice.getExchangeDetails(this.limit, offset).pipe(catchError(() => of(null))));
            if (wishStateObservables.length > 0) {
                return forkJoin(wishStateObservables).pipe(
                    map(res => {
                        return [...res, resObject.firstAPIResposnse];
                    }),
                    catchError((err) => { return of([]) })
                );
            }else{
                return [[resObject.firstAPIResposnse]];
            }
        }),
        map(siteLocations => {
            return siteLocations;
        }),
        catchError((err) => { return of([]) })
    );


    siteLocationsMerge$ = combineLatest([
        this.stieAMSDetailsItems$,
        this.stiePolesDetailsItems$,
        this.stieExchangesDetailsItems$,
    ]).pipe(map(([amsSiteLocations, polesLocations, exchangesLocations]) => {
        let combinedSiteLocations = [];
        amsSiteLocations.forEach((paginationList: SiteLocation[]) => {
            if(paginationList && paginationList.length>0){
                combinedSiteLocations.push(...paginationList);
            }
        });
        polesLocations.forEach((paginationList: SiteLocation[]) => {
            if(paginationList && paginationList.length>0){
                combinedSiteLocations.push(...paginationList);
            }
        });
        exchangesLocations.forEach((paginationList: SiteLocation[]) => {
            if(paginationList && paginationList.length>0){
                combinedSiteLocations.push(...paginationList);
            }
        });
        return combinedSiteLocations;
    }),shareReplay(1));

    setMarkers(markers: any[]) {
       this.markersSubject.next(markers);
    }

    
}