
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UsersService } from '../users.service';
import { Router } from '@angular/router';
// import { GridData } from '../../../../shared/models/common-model';
// import { CreateUserData, FormFields } from './../users.model';
import { FormGroup, FormBuilder, ReactiveFormsModule, Validators, FormControl } from '@angular/forms';
import { CustomTextField, CustomDropDown, TermsCondtion } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { TwCoreUIUtilService } from 'tw-core-ui';
import { ToastrService, GlobalConfig, ToastContainerDirective } from 'ngx-toastr';
import { UtilityService } from '../../services/utility.service';
import { CommonService } from 'src/app/services/common.service';
import { LoadingServiceService } from 'src/app/services/loading-service.service';
import { Title } from '@angular/platform-browser';
import { SharedService } from 'src/app/services/shared.service';
// import { DashboardService } from '../../../../modules/dashboard/dashboard.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.less']
})
export class CreateUserComponent implements OnInit {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  public aemLabelAuthoring: any;
  //CreateUserData;
  public createUserForm: FormGroup;
  public AppNameOptions = [];
  public roleOptions = [];
  public businessNames: any = [];
  userTypeMessage = '';
  userNameExistMessage = '';
  domainErrorMessage = '';
  public tableCol: Array<string> = [];
  public rowData = [];
  public businessRoleData: any;
  public isVisible: boolean = false;
  businessNameRequiredMsg: string = '';
  roleRequiredMsg: string = '';
  AMSroleRequiredMsg:string ='';
  public selectedCIDN: string = '';
  public createUserParams: Array<Object> = [];
  public newUserName: string;
  public selectedUser: string;
  public isRequired: Boolean = false;
  billingRoles = [];
  orderingRoles = [];
  assuranceRoles = [];
  chatRoles = [];
  billAdminRoles = [];
  billingOrderingRoles = [];
  roleList = [];
  flag: boolean;
  roleMapping
  amsRoleMapping
  // amsRoles = [];
  companyTypes = [];
  public isAMSdisable:boolean = true;
  public userNameModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMyUserName',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxuserName',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divuserNameValidationErrMsg',
    placeholderCaption: '',
    id: 'userName',
    tabindex: '',
    maxlength: '64',
    headerText: '',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: true,
    isKeyDownDefaultValidation: false,
    name: 'userName',
    formControlName: 'userName',
    isDisabled: false,
    showValidationIcon: true
  };
  public firstNameModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMyfirstName',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxfirstName',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divfirstNameValidationErrMsg',
    placeholderCaption: '',
    id: 'firstName',
    tabindex: '-1',
    maxlength: '64',
    headerText: '',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: true,
    isKeyDownDefaultValidation: false,
    name: 'firstName',
    formControlName: 'firstName',
    isDisabled: true,
    showValidationIcon: false
  };
  public lastNameModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMylastName',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxlastName',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divlastNameValidationErrMsg',
    placeholderCaption: '',
    id: 'lastName',
    tabindex: '-1',
    maxlength: '64',
    headerText: '',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: true,
    isKeyDownDefaultValidation: false,
    name: 'lastName',
    formControlName: 'lastName',
    isDisabled: true,
    showValidationIcon: false
  };
  public phoneModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblPhoneNumber',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxPhoneNumber',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divPhoneNumberValidationErrMsg',
    placeholderCaption: '',
    id: 'createUserphoneNumber',
    tabindex: '-1',
    maxlength: '10',
    headerText: 'Phone number',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: true,
    isKeyDownDefaultValidation: false,
    name: 'phoneNumber',
    formControlName: 'phoneNumber',
    isDisabled: true,
    showValidationIcon: false
  };
  public appNameDropDownModel: CustomDropDown = {
    isDisabled: true,
    accessibleHeaderId: 'appNameHeader'
  };
  public roleDropDownModel: CustomDropDown = {
    isDisabled: true,
    accessibleHeaderId: 'Role Required  Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  public businessNameDropDownModel: CustomDropDown = {
    isDisabled: true,
    accessibleHeaderId: 'Bussiness Name Required  Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  popUpCanceldataSource: TermsCondtion;
  // Future candidate to refactor to cui service component
  options: GlobalConfig;
  type = ['success', 'error', 'info', 'warning'];
  AMSRoleList:any =[];
  public infracoAdmin: boolean = false;
  infracoRoles = [];
  roles = [];
  AMSRoleSelected = [];
  constructor(
    private userService: UsersService,
    private fb: FormBuilder,
    private util: TwCoreUIUtilService,
    private toastr: ToastrService,
    private utility: UtilityService,
    private router: Router,
    private loader: LoadingServiceService,
    private commonService: CommonService,
    private titleService: Title,
    private sharedService: SharedService
    // private dashBoardSvc: DashboardService
  ) {
    this.titleService.setTitle('Amplitel Create a User');
    //  const userRoleDetails = this.dashBoardSvc.getroleDetails();
    // this.userService.getValidRoleDetails();
    // if (userRoleDetails.appList.indexOf('tw-hub') === -1 || userRoleDetails.roleList.length === 0 ||
    //   userRoleDetails.appList.length !== userRoleDetails.roleList.length ||
    //   userRoleDetails.roleList.length !== userRoleDetails.cidnList.length) {
    //   this.router.navigateByUrl('/access-denied');
    // } else {
    //    this.getAccessDetails();
    //   }
    // this.getRoles();
    // this.getLabels();
    this.getLabels();
    this.getAMSRoles();
    this.options = this.toastr.toastrConfig;
  }
  // getRoles() {
  //   const roles = (this.commonService.getroleDetails()).roleList;
  //   return new Promise((resolve, reject) => {
  //     this.userService.getAllRoles().then(res => {
  //       if (roles.includes('customeradmin') &&
  //         !roles.includes('ampliteladmin')) {
  //         this.roleOptions = res['customeradminRoles'];
  //       } else if (roles.includes('ampliteladmin')) {
  //         this.roleOptions = res['roles'];
  //       }
  //       this.getLabels();
  //       resolve(true);
  //     }).catch((err) => {
  //       reject(false);
  //     });
  //   });
  // }
  getAMSRoles() {
    // const roles = (this.commonService.getroleDetails()).roleList;
    return new Promise((resolve, reject) => {
      this.userService.getAMSRoles().then(res => {

      }).catch((err) => {
        reject(false);
      });
    });
  }
  // getAccessDetails() {
  //   if (this.userService.validUserRoles.length === 0) {
  //     return new Promise((resolve, reject) => {
  //       this.userService.getValidRoleDetails().then(res => {
  //         if (this.userService.validUserRoles.length === 0) {
  //      //     this.router.navigateByUrl('/access-denied');
  //         } else {
  //           this.options = this.toastr.toastrConfig;
  //           this.getLabels();
  //         }
  //         resolve(true);
  //       }).catch((err) => {
  //         reject(false);
  //       });
  //     });
  //   } else {
  //     this.options = this.toastr.toastrConfig;
  //     this.getLabels();
  //   }
  // }

  getLabels() {
    return new Promise((resolve, reject) => {
      const userRoles = this.userService.getUserRoles();
      userRoles.forEach(item => this.roleList.push(item.split(':')[1]));
      this.userService.getBusinessNames(null).then(res => {
        this.businessRoleData = res;
        if (this.businessRoleData.BusinessNameDetails.length > 0) {
          this.businessRoleData.BusinessNameDetails.forEach(entry => {
            if (Object.keys(entry).length > 0) {
              this.businessNames.push(entry['Business name'].trim());
            }
          });
          if (this.businessNames.length > 0) {
            this.userService.getCreateMyUserModel().then(() => {
              this.aemLabelAuthoring = this.userService.createUserLabelAuthoringDataObj;
              this.userNameModel.headerText = this.aemLabelAuthoring.form.userName;
              this.userNameModel.placeholderCaption = this.aemLabelAuthoring.form.userNamePlaceholder;
              this.firstNameModel.headerText = this.aemLabelAuthoring.form.firstName;
              this.firstNameModel.placeholderCaption = this.aemLabelAuthoring.form.firstNamePlaceholder;
              this.lastNameModel.headerText = this.aemLabelAuthoring.form.lastName;
              this.lastNameModel.placeholderCaption = this.aemLabelAuthoring.form.lastNamePlaceholder;
              this.userNameModel.validationMessage = this.aemLabelAuthoring.form.userNameValidationMessage;
              this.popUpCanceldataSource = this.aemLabelAuthoring.form.popUpCanceldataSource;
              this.userNameModel.requiredText = this.aemLabelAuthoring.form.userNameRequiredMessage;
              this.firstNameModel.validationMessage = this.aemLabelAuthoring.form.firstNameValidationMessage;
              this.lastNameModel.validationMessage = this.aemLabelAuthoring.form.lastNameValidationMessage;
              this.firstNameModel.requiredText = this.aemLabelAuthoring.form.firstNameRequiredMessage;
              this.lastNameModel.requiredText = this.aemLabelAuthoring.form.lastNameRequiredMessage;
              this.userTypeMessage = this.aemLabelAuthoring.form.userTypeRequiredMessage;
              this.phoneModel.headerText = this.aemLabelAuthoring.form.phoneNumber;
              this.phoneModel.placeholderCaption = this.aemLabelAuthoring.form.phonePlaceholder;
              this.phoneModel.validationMessage = this.aemLabelAuthoring.form.phoneNumberValidationMessage;
              this.phoneModel.requiredText = this.aemLabelAuthoring.form.phoneNumberRequiredMessage;
              this.businessNameRequiredMsg = this.aemLabelAuthoring.form.businessNameRequiredMessage;
              this.roleRequiredMsg = this.aemLabelAuthoring.form.roleRequiredMessage;
              this.AMSroleRequiredMsg = this.aemLabelAuthoring.form.AMSroleRequiredMsg;
              this.AppNameOptions = this.aemLabelAuthoring.form.userTypeDataOptions;
              this.userTypeMessage = this.aemLabelAuthoring.form.userTypeRequiredMessage;
              this.billingRoles = this.aemLabelAuthoring.form.billingRoles;
              this.orderingRoles = this.aemLabelAuthoring.form.orderingRoles;
              this.assuranceRoles = this.aemLabelAuthoring.form.assuranceRoles;
              this.chatRoles = this.aemLabelAuthoring.form.chatRoles;
              this.billAdminRoles = this.aemLabelAuthoring.form.billAdminRoles;
              if (this.businessNames.length <= 1) {
                if (this.businessNames[0]) {
                  this.createUserForm.controls['businessTypes'].setValue(this.businessNames[0]);
                } else {
                  //      this.router.navigateByUrl('/access-denied');
                }
              }
              this.businessNames = this.businessNames.sort();
              resolve(true);
            }).catch(() => {
              reject(false);
            });
          } else {
            this.router.navigateByUrl('/error');
          }
        } else {
          this.router.navigateByUrl('/error');
        }
      }).catch((err) => {
      });
    });
  }

  ngOnInit() {
    this.sharedService.focusOnElement('header_logo');
    this.createUserForm = this.fb.group({
      userName: ['', [Validators.required, Validators.pattern(this.utility.getEmailRegEx())]],
      firstName: [''],
      lastName: [''],
      phoneNumber: [''],
      businessTypes: [''],
      AppName: [''],
      userRole: [''],
      AMSRole :['',[]]
    });
    this.createUserForm.get('userName').valueChanges.subscribe(val => {
      this.userNameExistMessage = '';
      this.domainErrorMessage = '';
    });
    this.createUserForm.valueChanges.subscribe(val => {
      this.toastr.clear();
    });
  }

  cancelCreate() {
    this.toastr.clear();
    this.createUserForm.reset();
    this.createUserForm.markAsUntouched();
    this.rowData = [];
    this.createUserParams = [];
    this.isVisible = false;
    this.firstNameModel.isDisabled = true;
    this.lastNameModel.isDisabled = true;
    this.phoneModel.isDisabled = true;
    this.resetFormData();
    this.router.navigate(['manageUsers/myUser']);
  }

  cancelRole() {
    this.isVisible = true;
    this.resetFormData();
    this.sharedService.focusOnElement('addrole');
  }

  onSubmit(createUserTicket: any): any {
    const roles = [];
    let userRoles = this.userService.getUserRoles();
    if (userRoles && userRoles.length) {
      userRoles.forEach(item => {
        roles.push(item.split(':')[1].toLowerCase());
      });

      this.infracoAdmin = roles.some(val => this.infracoRoles.indexOf(val) > -1) ? true : false;
    }
    const userType = { 'infraco': this.infracoAdmin };

    if (this.createUserForm.valid) {
      this.loader.setLoader(true);
      if (this.rowData.length > 0) {
        let errMsg: string = 'Unable to create/update the user in Enquiry System, please contact Level-1 support team';
        this.loader.setLoader(true);
        let allAMSRoles:any=[];
        let amsRoleObj;
        this.rowData.forEach(row=>{
          row.amsRole.forEach(amsRole=>{    
              allAMSRoles.push(amsRole);
          })
        })
        allAMSRoles = [...new Set(allAMSRoles)];
        amsRoleObj = {
          'amsRole' : allAMSRoles.join(','),
          'amsCidn':this.createUserParams[0]['CIDN']
        }

        this.userService.createUser(this.createUserForm.value, amsRoleObj, this.createUserParams).then(res => {
          this.userService.createuserFormData = this.createUserForm.value;
          this.userService.createuserFormData['page'] = 'Create';
          this.loader.setLoader(false);
          this.router.navigate(['manageUsers/myUser']);
        }).catch((err) => {
          if (err.error.status === 500 && err.error.message.toLowerCase() === errMsg.toLowerCase()) {
            this.showToasterMessage(this.aemLabelAuthoring.form.errMsg, '', this.options, 'error');
          }
          else if (err.error.status === 409) {
            this.userNameExistMessage = this.aemLabelAuthoring.form.userNameMessage;
          } else if (err.error.status === 401 && err.error.message.toLowerCase() === 'userdomain is not authorized') {
            this.domainErrorMessage = this.aemLabelAuthoring.form.domainErrorMessage;
          } else {
            this.showToasterMessage(this.aemLabelAuthoring.form.createFailedMessage, '', this.options, 'error');
            this.util.bringFocusToElement(window.event, 'toast-close-button', false);
            setTimeout(() => (document.getElementById('toast-container').
              getElementsByClassName('toast-close-button')[0] as HTMLElement).focus(), 0);
          }
          setTimeout(() => {
            this.loader.setLoader(false);
          }, 5000);
        });
      } else {
        this.loader.setLoader(false);
        this.showToasterMessage(this.aemLabelAuthoring.form.roleAddMessage, '', this.options, 'error');
      }
    } else {
      this.validateCreateUser(this.createUserForm);
    }

  }

  validateCreateUser(formGroup: FormGroup) {
    const invalidControls = [];
    let control: any;
    const formControls = (form: FormGroup) => {
      Object.keys(form.controls).forEach(field => {
        control = form.get(field);
        if (control.invalid) {
          invalidControls.push(field);
          this.utility.scrollToElement(invalidControls[0]);
        }
      });
    };
    formControls(formGroup);
    invalidControls.forEach(invalidField => {
      const fieldName = <string>invalidField;
      formGroup.controls[fieldName].markAsTouched();
      invalidField = formGroup.controls[fieldName].invalid;
    });
    return invalidControls;
  }

  // Future candidate to refactor to cui service component
  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.options.autoDismiss = true;
    this.options.timeOut = 10000;
    this.options.positionClass = 'toast-bottom-right';
    this.options.disableTimeOut = false;
    this.options.closeButton = false;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    const event: Event = window.event;
    document.getElementsByClassName('overlay-container')[0].setAttribute('role', 'alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');
  }

  onSave(): any {
    if (this.createUserForm.valid) {
      this.saveData();
    } else {
      this.validateCreateUser(this.createUserForm);
    }
    this.sharedService.focusOnElement('addrole');
  }

  businessChange(businessType) {
    this.companyTypes = [];
    this.AMSRoleList = [];
    this.roleOptions = [];
    if (businessType !== this.createUserForm.value.businessTypes) {
      this.createUserForm.controls['userRole'].setValue('');
    }else{
      this.businessRoleData.BusinessNameDetails.map(x =>{
        if(x['Business name'] === businessType){
          this.companyTypes = x?.companyType?.split(',');
          this.roleOptions = [];
          this.companyTypes =  this.companyTypes.map(y=>{
            let type = y.trim();
            this.roleOptions = Array.from(new Set([...this.roleOptions,...this.businessRoleData['UIRoleMapping'].roleMapping[type]]))
            return type;
          });
        }
      })
    }
    // this.populateRole(businessType, this.createUserForm.value.AppName);
  }

  roleChange(portalRole){
    this.AMSRoleList = [];
    this.companyTypes.forEach(element=>{
      if(element == 'Vendor'){
        if(portalRole.toLowerCase()=='enquiryplusamsuser') this.AMSRoleList = ['Project Partner','Field Operative'];
        this.AMSRoleList.push("Customer Agent");
      }
      if(element == 'Asset Owner' && !(portalRole.toLowerCase()=='semanagement' || portalRole.toLowerCase()=='amplitelcommercial')){
        this.AMSRoleList.push("Customer");
      }
      // let amsrolemap = Object.keys(this.businessRoleData.amsRoleMapping);
      Object.keys(this.businessRoleData['UIRoleMapping'].amsRoleMapping).map(x=> {
        if(x === portalRole){
          this.AMSRoleList =  [...new Set([...this.AMSRoleList, ...this.businessRoleData['UIRoleMapping'].amsRoleMapping[portalRole].split(',')])];
        }
      }
      )
      this.createUserForm.controls['AMSRole'].reset();
      // this.businessRoleData.amsRoleMapping.map(x=> this.AMSRoleList.push(x[portalRole]));
     
    })
  }

  resetFormData() {
    if (this.isVisible) {
      this.createUserForm = new FormGroup({
        userName: new FormControl(this.createUserForm.value.userName, [Validators.required, Validators.pattern(this.utility.getEmailRegEx())]),
        firstName: new FormControl(this.createUserForm.value.firstName, [Validators.required, Validators.pattern(this.utility.getNameRegEx())]),
        lastName: new FormControl(this.createUserForm.value.lastName, [Validators.required, Validators.pattern(this.utility.getNameRegEx())]),
        phoneNumber: new FormControl(this.createUserForm.value.phoneNumber, [Validators.required, Validators.pattern(this.utility.getNumberRegEx())])
      });
    } else {
      this.createUserForm = new FormGroup({
        userName: new FormControl(this.createUserForm.value.userName, [Validators.required, Validators.pattern(this.utility.getEmailRegEx())]),
        firstName: new FormControl(this.createUserForm.value.firstName),
        lastName: new FormControl(this.createUserForm.value.lastName),
        phoneNumber: new FormControl(this.createUserForm.value.phoneNumber),
        businessTypes: new FormControl(this.createUserForm.value.businessTypes ? this.createUserForm.value.businessTypes : '', [Validators.required]),
        AppName: new FormControl('Towerco', [Validators.required]),
        // AppName: new FormControl(this.selectedUser, [Validators.required]),
        userRole: new FormControl('', [Validators.required]),
        AMSRole:new FormControl('',[Validators.required])
      });
    }
  }

  saveData() {
    this.selectedCIDN = this.createUserForm.value.businessTypes;
    this.isVisible = true;
    this.appNameDropDownModel.isDisabled = true;
    this.tableCol = this.aemLabelAuthoring.form.businessTableHeader;
    this.billingOrderingRoles = this.billingRoles.concat(this.orderingRoles);
    if (this.rowData.length > 0) {
      if (!this.rowData.some(value => value.role === this.createUserForm.value.userRole
        && value.business === this.createUserForm.value.businessTypes)) {
        if (this.rowData.some(val => this.billingRoles.indexOf(val.role.toLowerCase()) > -1 &&
          this.billingRoles.indexOf(this.createUserForm.value.userRole.toLowerCase()) > -1 &&
          val.business === this.createUserForm.value.businessTypes)) {
          this.showToasterMessage(this.aemLabelAuthoring.form.billingRoleMsg
            , '', this.options, 'error');
        } else if (this.rowData.some(val => this.orderingRoles.indexOf(val.role.toLowerCase()) > -1 &&
          this.orderingRoles.indexOf(this.createUserForm.value.userRole.toLowerCase()) > -1 &&
          val.business === this.createUserForm.value.businessTypes)) {
          this.showToasterMessage(this.aemLabelAuthoring.form.orderingRoleMsg
            , '', this.options, 'error');
        } else if (this.rowData.some(val => this.assuranceRoles.indexOf(val.role.toLowerCase()) > -1 &&
          this.chatRoles.indexOf(this.createUserForm.value.userRole.toLowerCase()) > -1 &&
          val.business === this.createUserForm.value.businessTypes)) {
          this.showToasterMessage(this.aemLabelAuthoring.form.assuranceRoleMsg
            , '', this.options, 'error');
        } else if (this.rowData.some(val => this.chatRoles.indexOf(val.role.toLowerCase()) > -1 &&
          this.assuranceRoles.indexOf(this.createUserForm.value.userRole.toLowerCase()) > -1 &&
          val.business === this.createUserForm.value.businessTypes)) {
          this.showToasterMessage(this.aemLabelAuthoring.form.chatRoleMsg
            , '', this.options, 'error');
        } else if (this.rowData.some(val => this.billAdminRoles.indexOf(val.role.toLowerCase()) > -1 &&
          this.billAdminRoles.indexOf(this.createUserForm.value.userRole.toLowerCase()) > -1 &&
          val.business === this.createUserForm.value.businessTypes)) {
          this.showToasterMessage(this.aemLabelAuthoring.form.assuranceRoleMsg
            , '', this.options, 'error');
        } else {
          this.addRow();
        }
      } else {
        this.showToasterMessage(this.aemLabelAuthoring.form.multipleRoleMessage
          , '', this.options, 'error');
      }
    } else {
      this.addRow();
    }
    this.resetFormData();
  }

  addRow() {
    this.rowData.push({
      'business': this.createUserForm.value.businessTypes, 'app': this.createUserForm.value.AppName,
      'role': this.createUserForm.value.userRole, 'amsRole':this.createUserForm.value.AMSRole, 'button': 'Remove'
    });
    this.createParams();
  }

  createParams() {
    const businessDetails: Array<Object> = this.businessRoleData.BusinessNameDetails;
    this.selectedCIDN = (businessDetails.find((businessDetails) => businessDetails['Business name'].trim() === this.selectedCIDN))['CIDN'];
    this.createUserParams.push({ 'roleValue': this.createUserForm.value.userRole, 'CIDN': this.selectedCIDN });
  }

  addRole() {
    let addfocusableElement;
    this.isVisible = false;
    if (this.rowData.length === 0) {
      this.appNameDropDownModel.isDisabled = false;
      this.selectedUser = '';
    } else {
      this.resetFormData();
      this.createUserForm.controls['businessTypes'].setValue(this.rowData[0].business);
      this.businessNameDropDownModel.isDisabled = true;
    }
    // commented this to remove bussiness popup to select only 1 business
    // if (this.businessNameDropDownModel.isDisabled) {
    //   this.showToasterMessage(this.aemLabelAuthoring.form.singleBusinessMessage
    //     , '', this.options, 'warning');
    // }
    this.resetFormData();
    this.AMSRoleList = [];
    // this.createUserForm.controls['businessTypes'].setValue(this.rowData[0].business);
    if (this.businessNames.length <= 1) {
      // this.createUserForm.controls['businessTypes'].setValue(this.businessNames[0]);
      // this.populateRole(this.businessNames[0], this.selectedUser);
    }
  }

  removeRow(index) {
    this.rowData.splice(index, 1);
    this.createUserParams.splice(index, 1);
    if (this.rowData.length === 0) {
      //this.appNameDropDownModel.isDisabled = false;
      this.businessNameDropDownModel.isDisabled = false;
    }
    if (!this.isVisible) {
      //    this.populateRole(this.createUserForm.value.businessTypes, this.createUserForm.value.AppName);
    }
  }

  validateUser() {
    if (this.createUserForm.value.userName.match(this.utility.getEmailRegEx())) {
      this.createUserForm.value.src_screen = 'create_user';
      this.userService.getUserData(this.createUserForm.value, 'create-user')
        .then(res => {
          if (res.status === 404) {
            this.notExistingUser();
          } else if (res.status === 200) {
            this.userService.existingUserData = res['data']['wholesale-users'][0];
            this.userService.existingUserData['page'] = 'Create';
            this.router.navigate(['manageUsers/modifyUsers']);
          }
        })
        .catch((err) => {
          if (err.error['code'] === 404) {
            this.notExistingUser();
          } else {
          }
        });
    } else {
      const control = this.createUserForm.get('userName');
      if (control.invalid) {
        this.createUserForm.controls['userName'].markAsTouched();
        return this.createUserForm.controls['userName'].invalid;
      }
    }
  }
  onBlurEvent() {
    this.flag = true;
  }

  notExistingUser() {
    this.userNameExistMessage = '';
    this.domainErrorMessage = '';
    this.isRequired = true;
    this.newUserName = this.createUserForm.value.userName;
    this.firstNameModel.isDisabled = false;
    this.firstNameModel.tabindex = "0";
    this.lastNameModel.isDisabled = false;
    this.lastNameModel.tabindex = "0";
    this.phoneModel.isDisabled = false;
    this.phoneModel.tabindex = "0";
    this.businessNameDropDownModel.isDisabled = false;
    this.isAMSdisable = false;
    this.roleDropDownModel.isDisabled = false;
    this.appNameDropDownModel.isDisabled = false;
  }

  ngOnDestroy() {
    // this.toastr.clear();
  }
  amsRoleChange(event) {
    this.AMSRoleSelected = [];
    if(event){
      event.forEach(element=>{
        this.AMSRoleSelected.push(element.value);
      })
    }

  }

}
