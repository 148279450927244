import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LoadingServiceService } from 'src/app/services/loading-service.service';
import { SiteDetailsPopupService } from 'src/app/services/site-details-popup.service';
import { showToasterMessage } from 'src/app/utlity/utlity';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-site-access-information-popup',
  templateUrl: './site-access-information-popup.component.html',
  styleUrls: ['./site-access-information-popup.component.less']
})
export class SiteAccessInformationPopupComponent implements OnInit {

  @Output() closePopupButtonEvent = new EventEmitter();
  @Input() siteReferenceId:any;
  @Input() siteAddress:any;
  @ViewChild('printContent') modal: ElementRef;
  options: GlobalConfig;
  siteAccessInformationData:any;
  siteInfo:any;
  setLandlordAndAccess:any;
  modalHeight:number = 0;
  constructor(
    private siteDetailsPopupService:SiteDetailsPopupService, 
    private loader: LoadingServiceService, 
    private toastr: ToastrService,
    private router: Router,) { }

  ngOnInit(): void {
    this.getSiteAccessInformationData();
  }
  calcumateHeight () {  
    this.modalHeight = this.modal.nativeElement.offsetHeight;
  }
  
  getSiteAccessInformationData(){
    this.loader.setLoader(true);
    this.siteDetailsPopupService.getSiteAccessInformation(this.siteReferenceId).subscribe({
      next: (res)=>{
        this.siteAccessInformationData = res.siteAccessDetails[0];
        this.loader.setLoader(false);
        this.siteInfo = [
          { title: 'Site Name', value: this.siteAccessInformationData['SiteName'] },
          { title: 'Amplitel Site Reference', value: this.siteAccessInformationData['AmplitelSiteReference'] },
          { title: 'Address', value: this.siteAddress },
          { title: 'Lat, Long', value: this.siteAccessInformationData['Lat-Long'] }
        ];
        this.setLandlordAndAccess = [
          {title: 'Landlord Comments', value: this.siteAccessInformationData['LandlordComments'], flag: 'true'},
          {title: 'Site Direction Notes', value: this.siteAccessInformationData['SiteDirectionNotes'], flag: 'true'},
          {title: 'Site Access Notes', value: this.siteAccessInformationData['SiteAccessNotes'], flag: 'true'},
          {title: 'Site Key Type', value: this.siteAccessInformationData['SiteKeyType'], flag: 'false'},
          {title: 'Site Access Condition', value: this.siteAccessInformationData['SiteAccessCondition'], flag: 'false'},
          {title: 'Site Access Restriction', value: this.siteAccessInformationData['SiteAccessRestriction'], flag: 'false'}
        ];
        
        setTimeout(() => this.calcumateHeight(), 10);
      },
      error: (err) => {
        this.loader.setLoader(false);
        const msg = err.status === 400 ? err.error.message : 'Something went wrong';
        showToasterMessage(msg, '', this.options, 'error', this.toastr);
      }
    })
  }
  redirectToSiteAccess() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/site-access'])
    );
    window.open(url, '_blank');
  }

  close(){
    this.closePopupButtonEvent.emit(false);
  }
}
