import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PageWiseFilterService {

  private filters: Map<string, any> = new Map<string, any>();  

  constructor() { }

  setFilter(pageName: string, filterValue: any) {
    this.filters.set(pageName, filterValue);
  }

  getFilter(pageName: string) {
    return this.filters.get(pageName);
  }

  clearFilters(){
    this.filters.clear();
  }
}
