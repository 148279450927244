import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer, SuperClusterOptions } from '@googlemaps/markerclusterer';
import { Subject, combineLatest, of } from 'rxjs';
import { MapStoreService } from '../../services/map-store.service';
import { SiteLocatorService } from '../../services/site-locator.service';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { SiteLocation, SiteLocationStore } from '../../models/site-locator.model';
import { SiteLocatorPopupService } from '../../services/site-locator-popup.service';
import { getSiteImage, getSiteImageHighlight } from '../../util/map-util';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnInit {

  private readonly destroy$ = new Subject();
  
  map: google.maps.Map;
  markers: google.maps.Marker[];
  stateMarkers: google.maps.Marker[] = [];

  towersMarkers: google.maps.Marker[] = [];
  polesMarkers: google.maps.Marker[] = [];
  exchangesMarkers: google.maps.Marker[] = [];
  rooftopMarkers: google.maps.Marker[] = [];
  landbankMarkers: google.maps.Marker[] = [];


  markerCluster: MarkerClusterer;
  oldMarkerContent: google.maps.Marker;
  oldSiteDetail: SiteLocation;
  parser = new DOMParser();

  mapLoaded = false;
  idleTimeOut;
  selectedMarker: google.maps.Marker;

  mapOptions: google.maps.MapOptions = {
    center: {
      lat: -25.2744,
      lng: 133.7751
    },

    gestureHandling: "cooperative",
    zoom: 4.3,
    mapId: '7b88c0a94a05e3a7',
    mapTypeControl: true,
    streetViewControl: true,
    scaleControl: true,
    rotateControl: true,
    tilt: 0,
    draggableCursor: 'default',
    scrollwheel:true
  };

  isLoading = true;

  vm$ = combineLatest([
    this.siteLocatorService.mapToken$.pipe(catchError((err) => {
      return of(null)
    })),
    this.mapStoreService.selectedLocation$,
    this.mapStoreService.siteLocationsMerge$,
    this.mapStoreService.store$
  ]).pipe(takeUntil(this.destroy$ ),map(([key, selectedLocation, siteLocationsMerge, store]) => {
      if (!this.map) {
        this.loadMap(key, store, siteLocationsMerge);
      }
      if (store.filterData && this.map && store.filterOptions.length > 0) {
        this.selectedMarker=null;
        this.stateMarkers = [];
        this.mapFitlerDrawMarkers(store.filterOptions)
      }
      if (selectedLocation) {
        this.onSearchSelectHilightMarker(selectedLocation);
      }
      else {
        this.resetMakerForPreviousPosition(null, null);
      }
  }));

  constructor(
    private siteLocatorService: SiteLocatorService,
    private mapStoreService: MapStoreService,
    private siteLocatorPopupService: SiteLocatorPopupService) { }

  ngOnInit(): void {
    this.isLoading = true;
   // this.loader.setLoader(true);
  }
  createResetControl() {
    const resetButton = document.createElement("button");
    resetButton.style.backgroundColor = "#ffffff";
    resetButton.style.border = "none";
    resetButton.style.borderRadius = "2px";
    resetButton.style.height = "40px";
    resetButton.style.boxShadow = "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px";
    resetButton.style.cursor = "pointer";
    resetButton.style.fontFamily = "Roboto,Arial,sans-serif";
    resetButton.style.fontSize = "18px";
    resetButton.style.margin = "10px";
    resetButton.style.padding = "0px 17px";
    resetButton.style.textAlign = "center";
    resetButton.textContent = "Reset";
    resetButton.title = "Click to reset the map";
    resetButton.type = "button";
  
    resetButton.addEventListener("click", () => {
      this.map.setZoom(4.3);
      this.siteLocatorPopupService.patchStore(null);
      this.mapStoreService.patchStoreSelectedLocation(null);
    });
    return resetButton;
  }  

  async loadMap(key: string, store, siteLocationsMerge) {
    const loader = new Loader({
      apiKey: key,
      version: "weekly",
      libraries: ["places"]
    });

    loader
      .importLibrary('maps')
      .then(({ Map }) => {
        
        this.mapOptions.zoomControlOptions = {
          position: google.maps.ControlPosition.LEFT_BOTTOM
        }
        this.mapOptions.fullscreenControlOptions = {
          position: google.maps.ControlPosition.LEFT_BOTTOM
        }
        this.mapOptions.streetViewControlOptions = {
          position: google.maps.ControlPosition.LEFT_BOTTOM,
          sources: [google.maps.StreetViewSource.GOOGLE]
        }
//this.loader.setLoader(false);
        this.map = new Map(document.getElementById("map"), this.mapOptions);

        const resetControlDiv = document.createElement('div');
        resetControlDiv.classList.add("reset-container");
        const resetControl = this.createResetControl();
        resetControlDiv.appendChild(resetControl);
        this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(resetControlDiv);
        this.markerCluster = new MarkerClusterer({
          map:this.map,
          markers: [],
          algorithmOptions: {radius: 100,minimumClusterSize: 200,ignoreHiddenMarkers:true, maxZoom: 16 } as SuperClusterOptions, 
          renderer:{
            render: ({ count, position }) => {
              return new google.maps.Marker({
                position: {
                  lat: position.lat(),
                  lng: position.lng(),
                },
                icon: {
                  path: google.maps.SymbolPath.CIRCLE,
                  strokeColor: '#00807B',
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                  fillColor: '#000',
                  fillOpacity: 1.0,
                  scale: 23,
                  anchor: new google.maps.Point(0, 0)
                },
                label: {
                  text: String(count),
                  color: 'white'
                },
                optimized: false
              });
            }
          }
        });
       // this.loader.setCustomLoader(true); 
        this.map.addListener('tilesloaded', () => {
          if (!this.mapLoaded) {              
            this.drawSitePostions({ data: siteLocationsMerge });
            this.mapLoaded = true;
          }          
        });
        this.map.addListener('zoom_changed', () => {  
          //this.isLoading = true;
          clearTimeout(this.idleTimeOut); 
        });
        this.map.addListener('idle', () => {          
          clearTimeout(this.idleTimeOut);
          if( this.selectedMarker){ //added this for single selection element as it was throwing max call size error
            this.markerCluster.clearMarkers();
            this.markerCluster.addMarker(this.selectedMarker);
            this.selectedMarker=null;
          }else if(this.stateMarkers?.length>0){           
            this.markerCluster.clearMarkers();
            const currentMarkers = this.stateMarkers?.filter(marker =>
              marker.getVisible() && this.map.getBounds().contains(marker.getPosition())
            );
            this.markerCluster.addMarkers(currentMarkers);            
          }else{
            this.markerCluster.clearMarkers();
            this.idleTimeOut = setTimeout(()=>{ //this is required for zoom event to handle              
              if (this.markers?.length > 0 && this.markerCluster && !this.selectedMarker) {              
                const currentMarkers = this.markers?.filter(marker => marker.getVisible() && this.map.getBounds().contains(marker.getPosition()));
                this.markerCluster = new MarkerClusterer({
                  map:this.map,
                  markers:currentMarkers,
                  algorithmOptions: {radius: 100,minimumClusterSize: 200,ignoreHiddenMarkers:true, maxZoom: 16 } as SuperClusterOptions, 
                  renderer:{
                    render: ({ count, position }) => {
                      return new google.maps.Marker({
                        position: {
                          lat: position.lat(),
                          lng: position.lng(),
                        },
                        icon: {
                          path: google.maps.SymbolPath.CIRCLE,
                          strokeColor: '#0bb9bf',
                          strokeOpacity: 1.0,
                          strokeWeight: 2,
                          fillColor: '#000',
                          fillOpacity: 1.0,
                          scale: 23,
                          anchor: new google.maps.Point(0, 0)
                        },
                        label: {
                          text: String(count),
                          color: 'white'
                        },
                        optimized: false
                      });
                    }
                  }
                });
              }
             this.isLoading = false
            });
          }      
        });
       // this.isLoading = false;
      })
      .catch((e) => {
      //  this.loader.setLoader(false);
      });
  }

  async setMarkersForFilters(type: string, marker: google.maps.Marker) {
    switch (type) {
      case 'Towers':
        this.towersMarkers.push(marker); break;
      case 'Poles':
        this.polesMarkers.push(marker); break;
      case 'Rooftops':
        this.rooftopMarkers.push(marker); break;
      case 'Exchanges':
        this.exchangesMarkers.push(marker); break;
      case 'Landbanks':
        this.landbankMarkers.push(marker); break;
    }
  }

  setMarkersDataWithFilter(type: string): google.maps.Marker[] {
    switch (type) {
      case 'Towers':
        return this.towersMarkers;
      case 'Poles':
        return this.polesMarkers;
      case 'Rooftops':
        return this.rooftopMarkers;
      case 'Exchanges':
        return this.exchangesMarkers;
      case 'Landbanks':
        return this.landbankMarkers;
      default:
        return [];
    }
  }
  mapFitlerDrawMarkers(filterOptions:string[]) {
    this.mapStoreService.patchStore({ filterData: false, filterOptions: filterOptions });
    this.markerCluster?.clearMarkers();
    this.markers =[];
    filterOptions.forEach(element => {
      const markersArray = this.setMarkersDataWithFilter(element);
      if(!filterOptions.includes('Towers') && element ==='Rooftops'){
        this.markers.push(...this.towersMarkers.filter(item=>(item as any).data.containsRooftop === 'Yes'));
      }
      for (let i = 0; i < markersArray.length; i++) {
        this.markers.push(markersArray[i])
      }
    });   
    const selectedMarkersData =this.markers.map(item=>(item as any).data);
    this.mapStoreService.setMarkers(selectedMarkersData);   
    this.map.setZoom(4.3);   
  }

  async drawSitePostions(locations: SiteLocationStore) {  
    await google.maps.importLibrary("marker");
    this.markerCluster?.clearMarkers();
    this.addMarkers(locations.data);    
  }
 
  stateFilterList(stateFilterList:SiteLocation[],siteState:string){
    this.stateMarkers =[];
    this.createMarkers(stateFilterList,false);
    this.placeSearch(siteState);
  }

  addMarkers(data:SiteLocation[]) {    
    this.createMarkers(data,true);
    this.mapFitlerDrawMarkers(['Towers', 'Poles', 'Rooftops', 'Exchanges', 'Landbanks']);
  }
  createMarkers(data:SiteLocation[],addToMarkersArray:boolean){
    const markerList = data.filter(item=>item.latitude && item.longitude).map((siteItem: SiteLocation) => {  
      siteItem.lat = siteItem.latitude;
      siteItem.lng = siteItem.longitude;
      const pinSvgMarkerView = new google.maps.Marker({
        position: this.getLocationLatLng(siteItem),
        icon: {
          url: getSiteImage(siteItem.type),
          strokeColor: '#00807B',
          strokeOpacity: 1.0,
          strokeWeight: 2,
          fillColor: '#000',
          fillOpacity: 1.0,
          scaledSize: new google.maps.Size(20, 20),
        },
        optimized: false,
        title: siteItem.type
      });
      (pinSvgMarkerView as any).data = siteItem;
      siteItem.displaySitePopup = false;
      if(addToMarkersArray){
        this.setMarkersForFilters(siteItem.type, pinSvgMarkerView);
      }
      pinSvgMarkerView.addListener("click", () => {
        if (this.oldMarkerContent) {
          this.oldSiteDetail.displaySitePopup = false;
        }
        this.resetMakerForPreviousPosition(pinSvgMarkerView, siteItem);
        this.map.setZoom(22);
        this.map.setCenter(this.getLocationLatLng(siteItem));
        pinSvgMarkerView.setIcon({ url:getSiteImageHighlight(siteItem.type), scaledSize: new google.maps.Size(30, 30) })
        siteItem.displaySitePopup = true;
        this.mapStoreService.patchStoreSelectedLocation(siteItem);
        this.siteLocatorPopupService.patchStore(siteItem);
      });
      return pinSvgMarkerView;
    });  
    if(!addToMarkersArray){
      this.stateMarkers = markerList;
    } 
  }
 
  getLocationLatLng(siteItem) {
    siteItem.lat = siteItem.latitude;
    siteItem.lng = siteItem.longitude;
    return siteItem;
  }

  async onSearchSelectHilightMarker(siteItem: SiteLocation) {
    await google.maps.importLibrary("marker");
    const typeArray = this.setMarkersDataWithFilter(siteItem.type);
    typeArray.forEach(marker => {
      if (marker.getPosition().lat() == siteItem.lat && marker.getPosition().lng() == siteItem.lng) {
        this.resetMakerForPreviousPosition(marker, siteItem);
        this.map.setCenter(this.getLocationLatLng(siteItem));
        this.selectedMarker =marker;
        marker.setIcon({ url: getSiteImageHighlight(siteItem.type), scaledSize: new google.maps.Size(30, 30) })
      }
    });
    this.map.setZoom(22);    
  }
  resetMakerForPreviousPosition(marker: google.maps.Marker, siteItem: SiteLocation) {
    if (this.oldMarkerContent) {
      this.oldMarkerContent.setIcon({ url: getSiteImage(this.oldSiteDetail.type), scaledSize: new google.maps.Size(20, 20) })
    }
    this.oldMarkerContent = marker;
    this.oldSiteDetail = siteItem;
  }

  
  placeSearch(queryVal: string) {
    let service: google.maps.places.PlacesService;
    const request = {
      query: queryVal,
      fields: ["geometry"],
    };
    service = new google.maps.places.PlacesService(this.map);
    service.findPlaceFromQuery(
      request,
      (
        results: google.maps.places.PlaceResult[] | null,
        status: google.maps.places.PlacesServiceStatus
      ) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && results) {
          this.map.setZoom(2);
          this.map.setCenter(results[0].geometry!.location!);
          this.map.fitBounds(results[0].geometry.viewport);
        }
      }
    );
  }
  reverseGeocodeSearch(lat: number,lng:number) {
    let service=new  google.maps.Geocoder();
    let selectedAddres:any = this.markers.filter(
      (item: any) => item.data.lat == lat && item.data.lng == lng
    )?.[0];   
    if(!selectedAddres){
        service.geocode({location:{lat:parseFloat(lat+''),lng:parseFloat(lng+'')}},
                (
                  results: google.maps.GeocoderResult[]| null,
                  status: google.maps.GeocoderStatus
                ) => {
                  if (status === google.maps.GeocoderStatus.OK && results) {
                    this.map.setZoom(20);
                    this.map.setCenter(results[0].geometry!.location!);
                    this.map.fitBounds(results[0].geometry.viewport);
                  }
                }
              );
    }else{
      this.mapStoreService.patchStoreSelectedLocation(selectedAddres.data);
      selectedAddres.data.displaySitePopup = true;
      this.siteLocatorPopupService.patchStore(selectedAddres.data);
    }
  }

  ngOnDestroy() {
   this.destroy$.unsubscribe();
  }
}
