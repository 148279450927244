<div class="page-bg">
  <div *ngIf="aemLabelAuthoring">
    <div class="container-fluid page-container top-alignment px-0">
      <h1 class="page-header1 ms-0" id="siteAccess">{{aemLabelAuthoring.siteAccess}}</h1>
      <!-- <p class="card-text amplitel">{{'amplitelText'|translate}}</p> -->
      <!-- <hr class="hr"> -->
      <!-- <h2 class="sub-header1">How to Request Site Access</h2> -->
      <div class="row">
        <div class="col-12 col-ms-6 col-md-3">
          <div class="card card1">
            <div class="card-body">
              <h3 class="card-title">Apply</h3>
              <p class="card-text" [innerHTML]="aemLabelAuthoring.applyText"></p>
              <ol class="card-ol">
                <a href="https://telstranetworkinduction-physicalsecurity.com.au/"><li>Complete Mandatory induction Training  
                </li></a>
                <a href="mailto:infracositeaccess@team.telstra.com"><li>Apply for Request Service Now Portal Access
                </li></a>
                </ol>
            </div>
          </div>
        </div>
        <div class="col-12 col-ms-6 col-md-3">
          <div class="card card1">
            <div class="card-body">
              <h3 class="card-title">Request Hazard Approval</h3>
              <p class="card-text" [innerHTML]="aemLabelAuthoring.crqText"></p>
            </div>
          </div>
        </div>
        <div class="col-12 col-ms-6 col-md-3">
          <div class="card card1">
            <div class="card-body">
              <h3 class="card-title">Request Amplitel Access Approval</h3>
              <p class="card-text" [innerHTML]="aemLabelAuthoring.l5ApprovalText"></p>
            </div>
          </div>
        </div>
        <div class="col-12 col-ms-6 col-md-3">
          <div class="card card1">
            <div class="card-body">
              <h3 class="card-title">Attendance Request Form  Approval</h3>
              <p class="card-text" [innerHTML]="aemLabelAuthoring.arfApproval"></p>
              <p class="card-text" [innerHTML]="aemLabelAuthoring.arfApprovalProceed"></p>
              
            </div>
          </div>
        </div>
      </div>

      <!-- <hr class="hr"> -->
      <!-- <h2 class="sub-header1">Resources</h2> -->
      <div class="btn-center">
        <button type="button" class="btn resources-card" tabindex="0" role="tabpanel"><a href="https://www.amplitel.com.au/sites/default/files/2023-10/TP002G01%20Physical%20Access%20Process_Oct_2023.pdf">More information can be found in the Physical Access Process document.
        </a></button>
        <!-- <div class="col-12 col-ms-6 col-md-3">
          <div class="resources-card card cursor-pointer" (click)="resourcesModal('One Card')" (keypress)="resourcesModal('One Card')" tabindex="0" role="tabpanel">
            <div class="card-body text-center text-white d-flex align-items-center justify-content-center">
              Request One Card
            </div>
          </div>
        </div>
        <div class="col-12 col-ms-6 col-md-3">
          <a href="https://www.telstrawholesale.com.au/facilities.html" target="_blank" class="resources-card card" tabindex="0" role="link">
            <div class="card-body text-center text-white d-flex align-items-center justify-content-center">
              <div>
                <div class="d-inline-block">InfraCo Buildings</div>&nbsp;<span>&#38;</span>&nbsp;<div class="d-inline-block">Shared Facilities Access</div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-12 col-ms-6 col-md-3">
          <a href="https://tnetworks.service-now.com/dcf" target="_blank" class="resources-card card" tabindex="0" role="link">
            <div class="card-body text-center text-white d-flex align-items-center justify-content-center">
              Access ServiceNow Portal
            </div>
          </a>
        </div>
        <div class="col-12 col-ms-6 col-md-3">
          <div class="resources-card card cursor-pointer"
            (click)="resourcesModal('New Customer Induction & Onboarding')" (keypress)="resourcesModal('New Customer Induction & Onboarding')" tabindex="0" role="tabpanel">
            <div class="card-body text-center text-white d-flex align-items-center justify-content-center">
              <div>
                <div class="d-inline-block">New Customer Induction</div>&nbsp;<span>&#38;</span>&nbsp;<div class="d-inline-block">Onboarding</div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div *ngIf="modalVisible" tabindex="0" class="modal resources-modal" [ngClass]="{'show' : 'modalVisible'}"
      style="z-index: 9999;" role="dialog" aria-hidden="false">
      <div class="modal-dialog modal-dialog-centered modal-lg" style="z-index: 1055;"
        [ngClass]="{'m-lg' : 'newCustomerLg'}">
        <div class="modal-content">
          <div class="modal-header no-border pb-0">
            <div class="row row-width">
              <h5 class="modal-title">
                {{modalTitle}}
              </h5>
            </div>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
              (click)="close()"></button>
          </div>
          <div class="modal-body">
            <div *ngIf="oneCard">
              <h5 class="modal-subtitle">To apply for a new or replacement One Card</h5>
              <p class="mb-0 modal-pera">Please email your request to: <a
                  href="mailto:infracositeaccess@team.telstra.com">infracositeaccess@team.telstra.com</a></p>
            </div>
            <div *ngIf="newCustomer">
              <h5 class="modal-subtitle mb-3">If you are a new customer requiring access to an Amplitel structure,
                please complete the following:</h5>
              <div class="mb-3">
                <h5 class="modal-subtitle">1. Online Telstra Site Induction Course</h5>
                <p class="modal-pera mb-0">Please complete the required Telstra Network (WINS) Induction using the
                  following link:
                  <a href="http://www.telstranetworkinduction-physicalsecurity.com.au/ "
                    target="_blank">http://www.telstranetworkinduction-physicalsecurity.com.au/ </a>
                </p>
              </div>
              <div class="mb-3">
                <h5 class="modal-subtitle">2. Apply for ServiceNow Access</h5>
                <p class="mb-0 modal-pera">Please email <a
                    href="mailto:infracositeaccess@team.telstra.com">infracositeaccess@team.telstra.com</a> to:</p>
                <p class="mb-0 modal-pera">- apply for access to the ServiceNow Portal</p>
                <p class="mb-0 modal-pera">- obtain a Telstra Employee Number</p>
              </div>
              <div class="modal-pera">
                More information about the Site Access Process can be found in the <a
                  href="https://www.amplitel.com.au/sites/default/files/2023-10/TP002G01 Physical Access Process_Oct_2023.pdf"
                  target="_blank">Physical Access Process document</a>.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-backdrop"></div>
    </div>
  </div>
</div>