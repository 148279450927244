import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SiteDetailsPopupLabels } from './site-details.model';
import { SiteDetailsPopupService } from '../../../services/site-details-popup.service';
import { Router } from '@angular/router';
import {saveAs as importedSaveAs} from "file-saver";
import { MapStoreService } from '../../services/map-store.service';
import { CommonService } from 'src/app/services/common.service';
import { LoadingServiceService } from 'src/app/services/loading-service.service';
import { GlobalConfig, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-site-details-popup',
  templateUrl: './site-details-popup.component.html',
  styleUrls: ['./site-details-popup.component.less']
})
export class SiteDetailsPopupComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild('closebutton') closebutton;

  @ViewChild('myFrame') myFrame: ElementRef;
  @ViewChild('child') child: ElementRef;
  

  iframeVisible: boolean = false;

  iframeLoading:boolean = true;

  modalVisible: boolean = true;

  public encryptedData: string;

  public decryptData: string;

  public aemLabelAuthoring: SiteDetailsPopupLabels;

  activeButton: string = 'digital-twin';

  @Output() closeButtonEvent = new EventEmitter();

  ///for refernce purpose , will remove after getting url
  iframeButtonUrl: { [key: string]: string } = {};

  iframeUrl: any = this.iframeButtonUrl['digital-twin'];

  responseData: string;

  // isCustomerOrAgentOnly:boolean;

  primaryCidn: string;

  options: GlobalConfig;

  isFullscreen: boolean = false;

  isFirstLoadFullscreen: boolean = true;

  roles:any;

  hasRolesForOrder:boolean = false;
  isColoOnly:boolean = false;

  isSiteAccessPopupShow: boolean = false;

  /////sample data
  @Input() siteDetailsData: any;

  // public userRoles;

  constructor(
    private sanitizer: DomSanitizer,
    private siteDetailsService: SiteDetailsPopupService,
    private router: Router,
    private mapStoreService: MapStoreService,
    private commonService: CommonService,
    private loader : LoadingServiceService,
    private toastr: ToastrService,
    private renderer: Renderer2
  ) {
    this.getLabels();
    this.options = this.toastr.toastrConfig;
  }

  public ngOnChanges() {
    this.siteDetailsData?.structureDetails?.sort(this.itemSort);
  }
  itemSort(a, b) {
    // const str1 = a.structureReferenceId.toLowerCase();
    // const str2 = b.structureReferenceId.toLowerCase();
    let str1 = a.structureReferenceId?.split('_').length?parseInt(a.structureReferenceId?.split('_')[1]):a.structureReferenceId;
    let str2 = b.structureReferenceId?.split('_').length?parseInt(b.structureReferenceId?.split('_')[1]):b.structureReferenceId;
    let comparison = 0;
    if (str1 > str2) {
      comparison = 1;
    }
    else if (str1 < str2) {
      comparison = -1;
    }
    return comparison;
  }

  public ngOnInit() {
    // this.mapStoreService.stieAMSDetailsItems$.subscribe((value) => {
    // const deatils = value[0]
    // deatils.map((item)=>{  item.id=item.siteReferenceId
    //      return item;})
    // })
    const userRoleDetails = this.commonService.getPrimaryValue();
    this.roles = userRoleDetails.roles;
    const associatedFor = userRoleDetails.associatedFor;
    if (userRoleDetails.cidn)
      this.primaryCidn = userRoleDetails.cidn;
    else
      this.primaryCidn = this.commonService.getroleDetails().adminCidns
        .length
        ? this.commonService.getroleDetails().adminCidns[0]
        : null;
    const orderNowRoles = ['customer', 'secustomer', 'customeragent', 'enquiryplusamsuser'];
    const coloRolesRules = ['customer', 'customeragent', 'enquiryplusamsuser']
    let coloRoles = [];
    let isSe;

    const temp = orderNowRoles.filter((item) => {
      if(this.roles?.includes(item)){
        if(coloRolesRules.includes(item)) coloRoles.push(item);
        if(item == 'secustomer') isSe = true;
        return true;
      } else return false;
    });
    if (temp.length) {
      // this.hasRolesForOrder = true;
      if(coloRoles.length && !isSe) this.isColoOnly = true;
      if(!associatedFor) this.hasRolesForOrder = true;
      if(coloRoles.length && associatedFor && associatedFor.includes('ams')) {
        this.hasRolesForOrder = true;
      }
      if(isSe && associatedFor && associatedFor.includes('se')) {
        this.hasRolesForOrder = true;
      }
    } else if(this.commonService.isAmplitelAdmin || this.commonService.isSEManagement) {
      this.hasRolesForOrder = true;
      this.isColoOnly = false
      // this.isCustomerOrAgentOnly = false;
    }
    // this.isCustomerOrAgentOnly = this.userRoles?.every(role => role === 'customer' || role === 'customeragent')


      // const hasCustomer = this.roles.includes('customer');
      // const hasCustomerAgent = this.roles.includes('customeragent');
      // const hasSECustomer = this.roles.includes('secustomer');
     
      // if (hasCustomer && !hasCustomerAgent && !hasSECustomer) {
      //   this.isCustomerOrAgentOnly = true;
      // } else if(!hasCustomer && hasCustomerAgent && !hasSECustomer) {
      //   this.isCustomerOrAgentOnly = true;
      // }else if(!hasCustomer && !hasCustomerAgent && hasSECustomer){
      //   this.isCustomerOrAgentOnly = false;
      // }
  }

  ngOnDestroy() {
    this.siteDetailsData.displaySitePopup = false;
  }
  redirectToEnquiry() {
    this.siteDetailsService.setDetails(this.siteDetailsData);
    this.router.navigateByUrl('/enquiry');
  }
  openIframe(siteReferenceId:string, structureReferenceId:string) {
      this.iframeVisible = true
      this.modalVisible = false
    this.siteDetailsService.getDigitalTwinUrlDetails(siteReferenceId, structureReferenceId).subscribe(ele=>{
      this.responseData = ele.token;
      if(ele && ele.token){
        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.responseData);
           this.iframeLoading = false;
      }
        else{
          this.iframeUrl = '';
          this.iframeLoading = false;
          this.showToasterMessage('Something went wrong.', '', this.options, 'error');
        }
    })
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    //this.toastr.overlayContainer = this.toastContainer;
    this.options.autoDismiss = true;
    this.options.timeOut = 10000;
    this.options.positionClass = 'toast-bottom-right';
    this.options.disableTimeOut = false;
    this.options.closeButton = false;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    const event: Event = window.event;
    document.getElementsByClassName('overlay-container')[0].setAttribute('role', 'alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');
  }

  downloadReport(siteReferenceId: string) {
    this.loader.setLoader(true);
    this.siteDetailsService
      .getPreliminaryReportDetails(siteReferenceId, this.primaryCidn)
      .subscribe({
        next: (ele) => {
          this.responseData = ele;
          //const filename = decodeURIComponent(ele);
          if (ele) {
            const currentDate = new Date();
            const formattedDate = `${currentDate.getFullYear()}-${(
              currentDate.getMonth() + 1
            )
              .toString()
              .padStart(2, '0')}-${currentDate
                .getDate()
                .toString()
                .padStart(2, '0')}T${currentDate
                  .getHours()
                  .toString()
                  .padStart(2, '0')}${currentDate
                    .getMinutes()
                    .toString()
                    .padStart(2, '0')}${currentDate
                      .getSeconds()
                      .toString()
                      .padStart(2, '0')}`;

            //       const base64Response = await fetch(`data:text/csv;base64,${ele['documentResource']}`);
            //       const blob = await base64Response.blob()
         
            importedSaveAs(
              ele['body'],
              siteReferenceId +
              ' Preliminary Information Report ' +
              formattedDate +
              '.' +
              'xls'
            );
          }
          this.loader.setLoader(false);
        },
        error: (err) => {
          this.loader.setLoader(false);
          if (err.status === 401) {
            this.router.navigateByUrl('/access-denied');
          } else
            this.showToasterMessage('Error while downloading the file. please try again later.', '', this.options, 'error');
        }
      })
  }

  siteAccessInformation(){
    this.isSiteAccessPopupShow = true;
    this.modalVisible = false;
  }
  closePopup(e:any){
    this.isSiteAccessPopupShow = e;
    this.modalVisible = true;
  }

  // downloadFile(file:string){
  //   const filename = decodeURIComponent(file);
  //   this.siteDetailsService.getPreliminaryReportDetails(filename).subscribe(async res => {
  //       if (res) {
  //         const base64Response = await fetch(`data:text/csv;base64,${res['documentResource']}`);
  //         const blob = await base64Response.blob()
     
  //         importedSaveAs(blob, filename+'.'+'xlsx');
  //       }
  //     }),((err) => {
  //       if (err.status === 401) {
  //         this.router.navigateByUrl('/access-denied');
  //       } else
  //         this.router.navigateByUrl('/error');
  //     });
  //   }

  close(closeSiteDetail:boolean) {
    if(closeSiteDetail){
      this.closeButtonEvent.emit(false);
      this.child.nativeElement.remove()
      this.modalVisible = false;
    }
    if (this.iframeVisible) {
      this.iframeVisible = false;
      this.modalVisible = true;
    }
    
  }

  setActive(buttonName: string) {
    if (this.activeButton !== buttonName) {
      this.activeButton = buttonName;
      const replacedUrl = this.responseData?.replace(/digital-twin/g, buttonName);
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(replacedUrl);
      //this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeButtonUrl[buttonName]);
    } else {
      this.activeButton = '';
      this.iframeUrl = '';
    }
  }

  openFullscreen() {
    const iframe = this.myFrame.nativeElement as HTMLDivElement;
    if(iframe){
      if(iframe.requestFullscreen){
        iframe.requestFullscreen();
      }
   else {
    document.exitFullscreen();
   }
  }
  }

  exitFullscreen(){
    if(document.exitFullscreen){
      document.exitFullscreen();
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  onfullscreenchange(e) {
    if(this.iframeVisible){
      this.modalVisible = false;
      if ((document as Document).fullscreenElement) {
        this.isFullscreen = true;
        this.isFirstLoadFullscreen = false;
       } else {
        this.isFullscreen = false;
      }
    }   
  }

  redirectToMyOrders(){

    const siteDetails = {
     siteId: this.siteDetailsData.siteReferenceId,
     siteName: this.siteDetailsData.siteName,
     rfnsaSiteNo: this.siteDetailsData.rfnsaSiteNo,
    }
    this.siteDetailsService.setDetails(siteDetails);
    this.router.navigateByUrl('my-orders/create-orders');
  }

  getLabels() {
    return new Promise((resolve, reject) => {
      this.siteDetailsService.getSitePopupModel().then(() => {
        this.aemLabelAuthoring = this.siteDetailsService.siteDetailsAuthoringDataObj;
        resolve(true);
      }).catch((err) => {
        reject(false);
      });
    });
  }
}
