import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SiteLocation } from '../models/site-locator.model';

@Injectable({
  providedIn: 'root'
})
export class SiteLocatorPopupService {

  constructor() { }

  private readonly _store = new BehaviorSubject(null);

  public readonly store$ = this._store.asObservable();

  patchStore(data: Partial<SiteLocation>): void {
    if (data) {
      this._store.next({
        ...this._store.value,
        ...data
      });
    } else {
      this._store.next(null);
    }
  }
}
