//modules
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrService, ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { TranslateModule } from 'ng2-translate';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BillingModule } from "./billing/billing.module";
import { CustomersModule } from './customers/customers.module';
import { UsersModule } from './users/users.module';
import { TicketsModule } from './tickets/tickets.module';
//Components
import { AppComponent } from './app.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { EnquiryFormComponent } from './enquiry-form/enquiry-form.component';
import { HeaderComponent } from './header/header.component';
import { LogoutComponent } from './logout/logout.component';
import { FooterComponent } from './footer/footer.component';
import { EnquirySuccessComponent } from './enquiry-success/enquiry-success.component';
import { EnquiryErrorComponent } from './enquiry-error/enquiry-error.component';
import { AcknowledgePageComponent } from './acknowledge-page/acknowledge-page.component';
import { AccessInfoComponent } from './site-access/accessinfo.component';

//Services
import { DataService } from './services/data-service.service';
import { CommonService } from './services/common.service';
import { CompanyNotificationsModule } from './company-notifications/company-notifications.module';
import { ProfileService } from './services/profile.service';
import { TicketSummaryService } from './tickets/services/ticket-summary.service';
import { SharedService } from './services/shared.service';
import { SiteDetailsPopupService } from './services/site-details-popup.service';
// Shared Core library
import { HamburgerMenuModule, BannerModule, SpinnerModule, TwCoreUIFormModule, AuthGuardService, AuthService } from 'tw-core-ui';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SetDefaultBusinessComponent } from './set-default-business/set-default-business.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { FaqComponent } from './faq/faq.component';
import { HeaderInterceptor } from './services/header-interceptor';
import { LoadingSpinnerModule } from './loading-spinner/loading-spinner.module';
import { SharedModule } from './shared/shared.module';
import { SuccessScreenModule } from './my-orders/success-screen/success-screen/success-screen.module';
import { PageWiseFilterService } from './services/page-wise-filter.service';
@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    FormsModule,
    TwCoreUIFormModule,
    BrowserAnimationsModule,
    HamburgerMenuModule,
    BannerModule,
    ToastrModule.forRoot(),
    ToastContainerModule,
    CustomersModule,
    UsersModule,
    SpinnerModule,
    CompanyNotificationsModule,
    BillingModule,
    TicketsModule,
    NgbModule,
    MatProgressBarModule,
    TranslateModule.forRoot(),
    LoadingSpinnerModule,
    SharedModule,
    SuccessScreenModule
  ],
  declarations: [
    AppComponent,
    LandingPageComponent,
    EnquiryFormComponent,
    HeaderComponent,
    FooterComponent,
    LogoutComponent,
    EnquirySuccessComponent,
    EnquiryErrorComponent,
    AcknowledgePageComponent,
    AccessInfoComponent,
    SetDefaultBusinessComponent,
    TermsAndConditionsComponent,
    FaqComponent,
  ],
  providers: [
    AuthService,
    AuthGuardService,
    DataService,
    TicketSummaryService,
    ToastrService,
    CommonService,
    ProfileService,
    SharedService,
    SiteDetailsPopupService,
    {
      provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true
    },
    PageWiseFilterService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
