import { Component, OnInit } from '@angular/core';
import { userManagerObj } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SharedService } from '../services/shared.service';
import { CommonService } from '../services/common.service';
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.less']
})
export class LogoutComponent implements OnInit {
  focusableElement: HTMLElement;

  constructor(private titleService: Title,private router: Router, private sharedService: SharedService,private commonService: CommonService) { 
    this.titleService.setTitle('Amplitel Logout');
  }
  private userObj: any = userManagerObj;
  public logoutUrl = this.userObj.authority + '/idp/startSLO.ping?TargetResource=' + this.userObj.post_logout_redirect_uri
  ngOnInit(): void {
  }
  logout() {
    this.commonService.userReport('logout')
    .then(res=>{})
    .catch(err=>{});
    window.location.href = this.logoutUrl;
    sessionStorage.clear();
    sessionStorage.removeItem('isLoginFlag');
  }

  cancel(){
    this.router.navigateByUrl('/');
  }
  
  cancelTab(){
    this.sharedService.focusOnElement('header_logo')   
  }

}
