import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { userManagerObj } from 'src/environments/environment';
import { CustomTextField, CustomTextAreaCounter, CustomDropDown, TooltipContent, CustomProgressBar } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { DataService } from '../services/data-service.service';
import { LoadingServiceService } from '../services/loading-service.service';
import { RouteGuardService } from '../services/route-guard.service';
import { Title } from '@angular/platform-browser';
import { ToastrService, GlobalConfig, ToastContainerDirective } from 'ngx-toastr';
import { UtilityService } from '../services/utility.service';
import { AttachmentDetails, AttachmentMetaData, CustomFileUpload, FileUpload } from '../tickets/file-uploads.model';
import { TicketSummaryService } from '../tickets/services/ticket-summary.service';
import { UsersService } from '../users/users.service';
import { SharedService } from '../services/shared.service';
import { SiteDetailsPopupService } from '../services/site-details-popup.service';
import { CommonService } from '../services/common.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-enquiry-form',
  templateUrl: './enquiry-form.component.html',
  styleUrls: ['./enquiry-form.component.less']
})
export class EnquiryFormComponent implements OnInit, OnDestroy {
  @Input() userName: string;
  @Input() userEmail: string;
  @Output() closeEnquiry = new EventEmitter();
  @ViewChild('tooltip') ngbToolTip: any;
  @ViewChild('fileUploadInput') fileUploadInput;
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  options: GlobalConfig;
  filedropFlag: boolean = false;
  poleId:any;
  addressId:any;
  focusableElement: HTMLElement;
  showMsg: boolean = false;
  subscription: any;
  siteAddress: any;
  siteId: any;
  result: string;
  selectedSiteName: string;
  selectedSiteRefId: string;
  selectedRfnsa: string;
  searchById = '';
  siteDetailsPopupData: any;
  serviceId: any;
  type: string;
  altContact = {
    ariaLabel: 'Would you like to add an Alternate contact?',
    tabindex:'0'
  };
  siteReferenceId = [];
  fileuploadObj: CustomFileUpload = {
    id: 'addCommentTextArea',
    formControlNameDescription: 'fileUploadComments',
    attachmentObj: [],
    fileUploadLabelAuthoring: {
      fileSizeLimit: '',
      fileSizeLimitMessage: '',
      fileZeroMessage: '',
      fileLimitMessage: '',
      invalidExtensionMessage: '',
      validExtensions: [],
      fileUnsuccessfulUploadMessage: '',
      fileInfo: '',
      fileuploadHeader: '',
      fileuploadDragText: '',
      fileuploadDragTextViewTicket: '',
      fileuploadBrowseLinkText: '',
      fileuploadDropText: '',
      fileuploadNoFileText: '',
      fileuploadDesktopAttchBtnTxt: '',
      fileUploadviewFileText: '',
      commentPlaceholder: '',
      enquirySuccessMsg: '',
      enquiryUnsuccessMsg: '',
      siteAccess: '',
      ticketSummaryErrMsg: ''
    }
  };
  // public bandwidthTooltipContentModel: TooltipContent = {
  //   content: '',
  //   automationTypeTooltipIcon: 'image',
  //   automationIdTooltipIcon: 'imgAddressInformation'
  // };

  public progressBarModel: CustomProgressBar = {
    statusLabel: '',
    showCompact: true,
    focus: false
  };
  fileuploadDragText: string;
  primaryName: string = sessionStorage.getItem('contactName') ? sessionStorage.getItem('contactName') : '';
  firstName: string = sessionStorage.getItem('firstName') ? sessionStorage.getItem('firstName') : '';
  lastName: string = sessionStorage.getItem('lastName') ? sessionStorage.getItem('lastName') : '';
  primaryMail: string = sessionStorage.getItem('email') ? sessionStorage.getItem('email') : '';
  primaryContactNumber: string;
  // cidn: string = sessionStorage.getItem('cidn') ? sessionStorage.getItem('cidn') : '';
  cidn:string;
  toggleAlternatecheck: boolean = false;
  createTicketObj: any;
  author: string;
  //   res = {
  //     "status": "OK",
  //     "code": 200,
  //     "message": "Successfully completed",
  //     "time": "2024-05-04T06:51:06.180+0000",
  //     "correlationId": "d1248cca-8cc3-48ec-a38b-35e9ce88e98f",
  //     "result": {
  //         "Build to Suit Services": {
  //             "Build to Suit Services": "subCategories"
  //         },
  //         "Billing Enquiry": {
  //             "Billing Enquiry": "subCategories"
  //         },
  //         "Colocation Enquiry": {
  //             "Colocation Enquiry": "subCategories"
  //         },
  //         "Deployment Services": {
  //             "Deployment Services": "subCategories"
  //         },
  //         "Property Enquiry": {
  //             "Property Enquiry": "subCategories"
  //         },
  //         "Tower Maintenance Enquiry": {
  //             "Structure Enquiry": "subCategories"
  //         },
  //         "Privacy Enquiry": {
  //             "Privacy Enquiry": "subCategories"
  //         },
  //         "Sales Enquiry": {
  //             "Sales Enquiry": "subCategories"
  //         },
  //         "Site Maintenance or Access Track Maintenance Enquiry": {
  //             "Site Maintenance or Access Track Maintenance Enquiry": "subCategories"
  //         },
  //         "General Enquiry": {
  //             "General Enquiry": "subCategories"
  //         }
  //     }
  // }
  categories: string[] = [];
  subcategories: string[] = [];
  resSubscribe: Subscription;
  altContactCheckBoxInstance: any;
  userData: any = {};
  categoryDetails: {
    [key: string]: {
      [key: string]: string
    }
  }
  // subcategories = ['test1', 'test2']
  public CategoryModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Enquiry type Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  public SubCategoryModel: CustomDropDown = {
    isDisabled: true,
    accessibleHeaderId: 'Enquiry sub type Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  isModalOpen: boolean = false;
  // caseDetailsService: boolean = false;
  validError: string;
  fileToUpload: FileList = null;
  fileArr: any[];
  @HostListener('document:keydown.enter', ['$event', '$event.target']) onKeydownHandler(event: KeyboardEvent) {
    const matCheckbox = document.getElementById('mat-checkbox-1-input');
    if (matCheckbox === event.target) {
      event.preventDefault();
    }
  }
  constructor(private fb: FormBuilder, private router: Router,
    // private dataService: DataService,
    private loader: LoadingServiceService,
    private utility: UtilityService,
    private routeService: RouteGuardService,
    private titleService: Title,
    private ticketSummaryService: TicketSummaryService,
    private usersService: UsersService,
    private toastr: ToastrService,
    private sharedService: SharedService,
    private siteDetailsPopupService: SiteDetailsPopupService,
    private common: CommonService

  ) {
    this.options = this.toastr.toastrConfig;
    this.titleService.setTitle('Amplitel - Make An Enquiry');
    this.ticketSummaryService.getTicketOptions().then(response => {
      this.fileuploadObj.fileUploadLabelAuthoring = (<any>response).fileUpload;
      this.fileuploadDragText = this.fileuploadObj.fileUploadLabelAuthoring.fileuploadDragText;
    }).catch((err) => {
      this.router.navigateByUrl('/error');
    });
  }

  

  uploadFile(event: any): void {
    if (event.item) {
      this.fileToUpload = event;
    } else if (event.files) {
      this.fileToUpload = event.files;
    }
    this.fileArr = Array.from(this.fileToUpload);
    Array.from(this.fileArr).forEach(fileItem => {
      const attachmentMetaData: AttachmentMetaData = new AttachmentMetaData();
      attachmentMetaData.validationError = '';
      attachmentMetaData.attachmentDetails = new AttachmentDetails();
      this.validateAndCreateFile(fileItem, attachmentMetaData);
      if (attachmentMetaData.attachmentDetails.fileName !== '' && this.validError === '') {
        if (this.fileuploadObj.attachmentObj.length >= 1) {
          this.ValidateMultipleAttach(this.fileuploadObj.attachmentObj, attachmentMetaData);
        }
        else {
          this.fileuploadObj.attachmentObj.push(attachmentMetaData);
        }
      }
    });
    this.fileUploadInput.nativeElement.value = '';
  }

  enquiryForm = this.fb.group({
    businessTypes: ['', Validators.required],
    subCategoryTypes: ['', Validators.required],
    contactName: [''],
    contactMobile: [''],
    contactEmail: [''],
    enquiryDetails: ['', [Validators.required, this.noWhitespaceValidator]],
    shortDesc: ['', [Validators.required, this.noWhitespaceValidator]],
    siteId: [''],
    siteAddress: ['']
  });
  public ContactNameModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblContactName',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxContactName',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divCheckServiceValidationErrMsg',
    placeholderCaption: 'Enter Name',
    id: 'contactName',
    tabindex: '0',
    maxlength: '100',
    headerText: 'Name',
    headerTextAccessible: 'Name',
    inputText: '',
    requiredText: 'Please enter a valid Contact Name',
    validationMessage: 'Please enter a valid Contact Name',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'contactName',
    formControlName: 'contactName',
    isDisabled: false,
    showValidationIcon: false
  };
  public ContactNumberModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblContactNumber',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxContactNumber',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divCheckServiceValidationErrMsg',
    placeholderCaption: 'Enter Contact Number',
    id: 'contactMobile',
    tabindex: '0',
    maxlength: '17',
    headerText: 'Contact Number',
    headerTextAccessible: 'Contact Number',
    inputText: '',
    requiredText: 'Please enter a valid Australian Number',
    validationMessage: 'Please enter a valid Australian Number',
    pattern: '^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'contactMobile',
    formControlName: 'contactMobile',
    isDisabled: false,
    showValidationIcon: false
  };
  public emailModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblemail',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxemail',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divCheckServiceValidationErrMsg',
    placeholderCaption: 'Enter Email Address',
    id: 'contactEmail',
    tabindex: '0',
    maxlength: '100',
    headerText: 'Email Address',
    headerTextAccessible: 'Email Address',
    inputText: '',
    requiredText: 'Please enter a valid Email Address',
    validationMessage: 'Please enter a valid Email Address',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'contactEmail',
    formControlName: 'contactEmail',
    isDisabled: false,
    showValidationIcon: false
  };
  private userObj: any = userManagerObj;
  public fieldIds = ['enquiryDetails', 'shortDesc'];
  public logoutUrl = this.userObj.authority + '/idp/startSLO.ping?TargetResource=' + this.userObj.post_logout_redirect_uri
  // public disableContactMode = false;

  ngOnInit() {

    this.cidn = this.common.getPrimaryValue().cidn;
    if(!this.cidn) this.cidn = this.common.getroleDetails().adminCidns.length?this.common.getroleDetails().adminCidns[0]:null;
    this.resSubscribe = this.common.Modalsubject.subscribe(res => {
      this.result = res;
    })
    this.subscription = this.siteDetailsPopupService.siteDetails$.subscribe(
      (siteDetails) => {
        this.siteDetailsPopupData = siteDetails;
        this.type = this.siteDetailsPopupData.type;
        this.siteId = this.siteDetailsPopupData.siteReferenceId;
        this.poleId = this.siteDetailsPopupData.poleId;
        this.addressId = this.siteDetailsPopupData.addressId;
        this.siteAddress = this.siteDetailsPopupData.address;
        this.enquiryForm.get('siteId').setValue(this.siteId)
        this.enquiryForm.get('siteAddress').setValue(this.siteAddress)
      })
    this.author = this.primaryName ? this.primaryName.toUpperCase().split(' ').map(n => n[0]).join('').slice(0, 2) : '';
    const data = { 'userName': this.primaryMail, src_screen: 'enquiry' };
    this.usersService.getUserData(data, 'enquiry').then(res => {
      if (res.status === 404) {
        this.primaryContactNumber = '';
      } else if (res.status === 200) {
        this.userData = res['data']['wholesale-users'][0];
        this.primaryContactNumber = this.userData.phoneNumbers[0].value;
      }
    }).catch((err) => {
    });

    this.loader.setLoader(true);
    this.ticketSummaryService.getCategories().then((res: any) => {
      this.loader.setLoader(false);
      this.categoryDetails = res;
      this.categories = Object.keys(res);
      if(this.siteId || this.addressId || this.poleId){
        const categoriesToExclude = ["Build to Suit Services", "Deployment Services", "Structural Services"];
        this.categoryDetails = Object.keys(res).reduce((acc,key)=>{
          if(!categoriesToExclude.includes(key)){
            acc[key]= res[key];
          }
          return acc;
        }, {})

        this.categories = Object.keys(this.categoryDetails);
          
        
      }
    switch(this.result){
      case 'deploymentServices':
        this.enquiryForm.get('businessTypes').setValue('Deployment Services')
        this.enquiryForm.get('subCategoryTypes').setValue('Deployment Services')
        this.subcategories = ['Deployment Services'];
        // this.CategoryModel.isDisabled = true;
        // this.SubCategoryModel.isDisabled = true;
        break;
      case 'buildSuit':
        this.enquiryForm.get('businessTypes').setValue('Build to Suit Services')
        this.enquiryForm.get('subCategoryTypes').setValue('Build to Suit Services')
        this.subcategories = ['Build to Suit Services'];
        // this.CategoryModel.isDisabled = true;
        // this.SubCategoryModel.isDisabled = true;
        break;
        case 'structuralServices':
        this.enquiryForm.get('businessTypes').setValue('Structural Services')
        this.enquiryForm.get('subCategoryTypes').setValue('Structural Services')
        this.subcategories = ['Structural Services'];
        // this.CategoryModel.isDisabled = true;
        // this.SubCategoryModel.isDisabled = true;
        break;
        case 'Colocation':
          this.enquiryForm.get('businessTypes').setValue('Colocation Enquiry')
          this.enquiryForm.get('subCategoryTypes').setValue('Colocation Enquiry')
          this.subcategories = ['Colocation Enquiry'];
          break;
        default:
          break;
          // if (this.type) {
          //     this.enquiryForm.get('businessTypes').setValue('Site Maintenance or Access Track Maintenance Enquiry')
          //     this.categoryChanged(this.enquiryForm.value.businessTypes);
          //   }
          //   else {
      
          //     this.enquiryForm.get('businessTypes').setValue('')
          //     this.enquiryForm.get('subCategoryTypes').setValue('')
          //   }
    }
  }
  )
      .catch((err) => {
        this.router.navigateByUrl('/error');
      });
  }


  categoryChanged(category: string): void {
    if (category) {
      this.subcategories = [];
      this.SubCategoryModel.isDisabled = false;
      this.subcategories = Object.keys(this.categoryDetails[category]).sort();
      this.showMsg = false;
    }
  }
  subCategoryChanged(event: string) {
    if (event === 'Site Maintenance or Access Track Maintenance Enquiry') {
      this.showMsg = true;
      (<HTMLInputElement>document.getElementById('enquiryDetails')).disabled = true;
      (<HTMLInputElement>document.getElementById('shortDesc')).disabled = true;
    } else {
      this.showMsg = false;
      (<HTMLInputElement>document.getElementById('enquiryDetails')).disabled = false;
      (<HTMLInputElement>document.getElementById('shortDesc')).disabled = false;
    }
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  onSubmit() {

    let body;
    this.loader.setLoader(true);
    this.enquiryForm.get('enquiryDetails').setValue((<HTMLInputElement>document.getElementById('enquiryDetails')).value.trim());
    this.enquiryForm.get('shortDesc').setValue((<HTMLInputElement>document.getElementById('shortDesc')).value.trim());
    const enquiryValues = this.enquiryForm.value;
    this.enquiryForm.reset();
    this.routeService.setEnquirySubmitted(true);

    if (this.altContactCheckBoxInstance.checked) {
      if (this.fileuploadObj.attachmentObj.length !== 0) {
        body = {
          "cidn": this.cidn,
          "userId": this.primaryMail,
          "firstName": this.firstName,
          "lastName": this.lastName,
          "contactNumber": this.primaryContactNumber,
          "contactEmail": this.primaryMail,
          "ticketCategory": enquiryValues.businessTypes,
          "ticketSubCategory": enquiryValues.subCategoryTypes,
          "ticketShortDesc": enquiryValues.shortDesc,
          "ticketDetails": enquiryValues.enquiryDetails,
          "secContactName": enquiryValues.contactName,
          "secContactNumber": enquiryValues.contactMobile,
          "secContactEmail": enquiryValues.contactEmail,
          "channel": "Portal",
          "isAttachmentPresent": "true",
          "siteReferenceId": enquiryValues.siteId,
          "address": enquiryValues.siteAddress
        };
      } else {
        body = {
          "cidn": this.cidn,
          "userId": this.primaryMail,
          "firstName": this.firstName,
          "lastName": this.lastName,
          "contactNumber": this.primaryContactNumber,
          "contactEmail": this.primaryMail,
          "ticketCategory": enquiryValues.businessTypes,
          "ticketSubCategory": enquiryValues.subCategoryTypes,
          "ticketShortDesc": enquiryValues.shortDesc,
          "ticketDetails": enquiryValues.enquiryDetails,
          "secContactName": enquiryValues.contactName,
          "secContactNumber": enquiryValues.contactMobile,
          "secContactEmail": enquiryValues.contactEmail,
          "channel": "Portal",
          "isAttachmentPresent": "false",
          "siteReferenceId": enquiryValues.siteId,
          "address": enquiryValues.siteAddress
        };
      }
    } else {
      if (this.fileuploadObj.attachmentObj.length !== 0) {
        body = {
          "cidn": this.cidn,
          "userId": this.primaryMail,
          "firstName": this.firstName,
          "lastName": this.lastName,
          "contactNumber": this.primaryContactNumber,
          "contactEmail": this.primaryMail,
          "ticketCategory": enquiryValues.businessTypes,
          "ticketSubCategory": enquiryValues.subCategoryTypes,
          "ticketShortDesc": enquiryValues.shortDesc,
          "ticketDetails": enquiryValues.enquiryDetails,
          "secContactName": '',
          "secContactNumber": '',
          "secContactEmail": '',
          "channel": "Portal",
          "isAttachmentPresent": "true",
          "siteReferenceId": enquiryValues.siteId,
          "address": enquiryValues.siteAddress
        };
      } else {
        body = {
          "cidn": this.cidn,
          "userId": this.primaryMail,
          "firstName": this.firstName,
          "lastName": this.lastName,
          "contactNumber": this.primaryContactNumber,
          "contactEmail": this.primaryMail,
          "ticketCategory": enquiryValues.businessTypes,
          "ticketSubCategory": enquiryValues.subCategoryTypes,
          "ticketShortDesc": enquiryValues.shortDesc,
          "ticketDetails": enquiryValues.enquiryDetails,
          "secContactName": '',
          "secContactNumber": '',
          "secContactEmail": '',
          "channel": "Portal",
          "isAttachmentPresent": "false",
          "siteReferenceId": enquiryValues.siteId,
          "address": enquiryValues.siteAddress
        };
      }
      if (this.result === 'buildSuit' || this.result === 'deploymentServices' || this.result === 'structuralServices' || this.result === 'Colocation') {
        delete body.siteReferenceId;
        delete body.address;
      }
    }

    this.ticketSummaryService.createService(body).then((response: any) => {
      this.createTicketObj = response.result;
      if (this.fileuploadObj.attachmentObj.length !== 0) {
        this.allAttchmentUpload(this.fileuploadObj.attachmentObj, this.createTicketObj.sysTicketId);
      } else {
        this.loader.setLoader(false);
        this.showToasterMessage(this.fileuploadObj.fileUploadLabelAuthoring.enquirySuccessMsg, '', this.options, 'success');
        this.router.navigate([`ticket-summary/${this.cidn}/${this.createTicketObj.ticketNumber}`]);
      }
    }).catch(err => {
      this.loader.setLoader(false);
      this.showToasterMessage(this.fileuploadObj.fileUploadLabelAuthoring.enquiryUnsuccessMsg, '', this.options, 'error');
      this.router.navigateByUrl('/error');
    });
  }

  blurredOut(event, enquiryForm, name: string) {
    if (!enquiryForm.pristine || enquiryForm.touched) {
      if (!enquiryForm.get(name).valid && !enquiryForm.valid) {
        this.sharedService.focusOnElement('error-msg-id');
        this.fieldIds.map((f) => {
          if (f !== name) {
            (<HTMLInputElement>document.getElementById(f)).disabled = true;
          }
        });
        // this.disableContactMode = true;
        this.CategoryModel.isDisabled = true;
        this.SubCategoryModel.isDisabled = true;
      }
    }
  }
  changeInValue(event, enquiryForm, name: string) {
    if (!this.enquiryForm.pristine || this.enquiryForm.touched) {
      if (this.enquiryForm.get(name).valid) {
        this.fieldIds.map((f) => {
          if (f !== name) {
            (<HTMLInputElement>document.getElementById(f)).disabled = false;
          }
        });
        // this.disableContactMode = false;
        this.CategoryModel.isDisabled = false;
        if (enquiryForm.value.businessTypes) {
           this.SubCategoryModel.isDisabled = false;
        }
        if (this.result === 'buildSuit' || this.result === 'deploymentServices' || this.result === 'structuralServices' || this.result === 'Colocation') {
          this.SubCategoryModel.isDisabled = false;
          this.CategoryModel.isDisabled = false;
        }
      }
    }
  }

  public getAriaLabel() {
    return ('Write your short description below' + '. Character limit: ' + 160);
  }

  public getAriaLabels() {
    return ('Write your detailed description below' + '. Character limit: ' + 10000);
  }
  cancel() {
    this.isModalOpen = true;
    setTimeout(() => {
      this.focusableElement = document.getElementById('closeDiclaimer');
      this.focusableElement.focus();
    }, 1);
    this.sharedService.trapFocusInModal('closeDiclaimer', 'cancelDisclaimer');
  }

  homePage() {
    if(this.result === 'buildSuit' || this.result === 'deploymentServices' || this.result === 'structuralServices' || this.result === 'Colocation'){
      this.router.navigateByUrl('/services');
    }else{
      this.router.navigateByUrl('/site-locator');
    }
    
  }
  cancelTab() {
    this.sharedService.focusOnElement('closeDiclaimer');
  }

  onDrop(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.filedropFlag = false;
    if (event.dataTransfer.files) {
      this.uploadFile(event.dataTransfer);
    }
  }

  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
    this.filedropFlag = true;
    event.dataTransfer.dropEffect = 'copy';
  }

  onDragLeave(event) {
    event.stopPropagation();
    event.preventDefault();
    this.filedropFlag = false;
  }

  keyEnterEvent(evt: KeyboardEvent, value) {
    const KEY_ENTER = 13;
    const keyCode = evt.which;
    const validKeys = [KEY_ENTER];
    if (validKeys.indexOf(keyCode) >= 0) {
      evt.preventDefault();
      evt.stopPropagation();
      this.clearFolderSelection(value);
    }
  }

  // resetAll() {
  //   this.fileuploadObj.attachmentObj = [];
  //   this.filedropFlag = false;
  // }

  getFilesCount(): number {
    return this.fileuploadObj.attachmentObj.length;
  }

  clearFolderSelection(value: AttachmentMetaData) {
    this.clearFileFields(value);
    this.fileUploadInput.nativeElement.value = '';
    //this.allFilesUploadedCheck();
  }

  validateAndCreateFile(fileItem: File, attachmentMetaData: AttachmentMetaData) {
    this.validError = '';
    const fileExtension = fileItem.name.split('.')[fileItem.name.split('.').length - 1].toUpperCase();
    attachmentMetaData.attachmentDetails.size = fileItem.size.toString();
    attachmentMetaData.attachmentDetails.fileName = fileItem.name;
    attachmentMetaData.isUploadDone = '';
    attachmentMetaData.canShowProgressBar = true;
    if ((<any>this.fileuploadObj.fileUploadLabelAuthoring.validExtensions).indexOf(fileExtension) === -1) {
      attachmentMetaData.validationError = this.fileuploadObj.fileUploadLabelAuthoring.invalidExtensionMessage;
      this.validError = this.fileuploadObj.fileUploadLabelAuthoring.invalidExtensionMessage;
    } else if (fileItem.size > (<number><any>this.fileuploadObj.fileUploadLabelAuthoring.fileSizeLimit)) {
      attachmentMetaData.validationError = this.fileuploadObj.fileUploadLabelAuthoring.fileSizeLimitMessage;
      this.validError = this.fileuploadObj.fileUploadLabelAuthoring.fileSizeLimitMessage;
    } else if (fileItem.size === 0) {
      attachmentMetaData.validationError = this.fileuploadObj.fileUploadLabelAuthoring.fileZeroMessage;
      this.validError = this.fileuploadObj.fileUploadLabelAuthoring.fileZeroMessage;
    } else {
      // const reader = new FileReader();
      // reader.readAsDataURL(fileItem);
      if (fileExtension === 'MSG') {
        attachmentMetaData.attachmentDetails.ContentType = 'message/rfc822';
      } else {
        attachmentMetaData.attachmentDetails.ContentType = fileItem.type;
      }
    }

    attachmentMetaData.attachmentDetails.file = fileItem;
    if (attachmentMetaData.attachmentDetails.fileName) {
      attachmentMetaData.isUploadDone = '3bb07ff9-5204-4b55-90d4-bcf8a93defd5';
      // setTimeout(() => {
      // attachmentMetaData.isUploadDone = '3bb07ff9-5204-4b55-90d4-bcf8a93defd5';
      // attachmentMetaData.canShowProgressBar = false;
      // }, 1000);
      attachmentMetaData.canShowProgressBar = false;
    };
    // if (!attachmentMetaData.validationError) {
    //   const reader = new FileReader();
    //   reader.readAsBinaryString(fileItem);

    //   
    //   reader.onload = () => {
    //     attachmentMetaData.attachmentDetails.file = 
    //       (<String>reader.result).substring(((<String>reader.result).
    //         indexOf('base64,') + 7), (<String>reader.result).length);
    //     if (attachmentMetaData.attachmentDetails.fileName) {
    //       attachmentMetaData.isUploadDone = '3bb07ff9-5204-4b55-90d4-bcf8a93defd5';
    //       setTimeout(() => {
    //         attachmentMetaData.canShowProgressBar = false;
    //       }, 1000);
    //     };
    //   }
    // }
    // console.log(attachmentMetaData);
  }

  ValidateMultipleAttach(Arr, attachmentMetaData: AttachmentMetaData) {
    let fileSize: number = 0;
    this.validError = '';
    Arr.map(val => {
      fileSize += parseInt(val.attachmentDetails.size);
      // if(val.attachmentDetails.fileName === attachmentMetaData.attachmentDetails.fileName){
      //   attachmentMetaData.validationError = this.fileuploadObj.fileUploadLabelAuthoring.fileNameMessage;
      // }
    });
    fileSize += parseInt(attachmentMetaData.attachmentDetails.size);
    if (fileSize >= 8388608) {
      attachmentMetaData.validationError = this.fileuploadObj.fileUploadLabelAuthoring.fileSizeLimitMessage;
      this.validError = this.fileuploadObj.fileUploadLabelAuthoring.fileSizeLimitMessage;
    }
    if (Arr.length >= 10) {
      attachmentMetaData.validationError = this.fileuploadObj.fileUploadLabelAuthoring.fileLimitMessage;
      this.validError = this.fileuploadObj.fileUploadLabelAuthoring.fileLimitMessage;
    }
    if (this.validError === '') {
      this.fileuploadObj.attachmentObj.push(attachmentMetaData);
    }
  }
  clearFileFields(value: AttachmentMetaData) {
    this.validError = '';
    this.fileuploadObj.attachmentObj.splice(this.fileuploadObj.attachmentObj.indexOf(value), 1);
    //this.attachmentData.emit(this.fileuploadObj.attachmentObj);
  }

  clearAllAttachments() {
    this.validError = '';
    this.fileuploadObj.attachmentObj = [];
  }

  allAttchmentUpload(AttachmentArr: AttachmentMetaData[], sysTicketId: string) {
    AttachmentArr.forEach((singleAtt, i, array) => {
      this.ticketSummaryService.createSingleAttachment([singleAtt.attachmentDetails.file], sysTicketId).then(res => {
        if (i === array.length - 1) {
          this.loader.setLoader(false);
          this.showToasterMessage(this.fileuploadObj.fileUploadLabelAuthoring.enquirySuccessMsg, '', this.options, 'success');
          this.router.navigate([`ticket-summary/${this.cidn}/${this.createTicketObj.ticketNumber}`]);
        }
      }).catch((err) => {
        if (i === array.length - 1) {
          this.loader.setLoader(false);
          this.router.navigate([`ticket-summary/${this.cidn}/${this.createTicketObj.ticketNumber}`]);
          this.showToasterMessage(this.fileuploadObj.fileUploadLabelAuthoring.fileUnsuccessfulUploadMessage, '', this.options, 'success');
        }
      });
    });
  }

  checkboxInstance(event: any) {
    this.altContactCheckBoxInstance = event.checkBox;
  }

  selectCheckbox(event) {
    this.altContactCheckBoxInstance.checked = event;
    let idsArray = ['contactName', 'contactMobile', 'contactEmail'];
    if (this.altContactCheckBoxInstance.checked) {
      this.toggleAlternatecheck = true;
      this.fieldIds.concat(idsArray);
      this.enquiryForm.get('contactName').setValidators([Validators.required, Validators.pattern(this.utility.getNameRegEx())]);
      this.enquiryForm.get('contactEmail').setValidators([Validators.required, Validators.pattern(this.utility.getEmailRegEx())]);
      this.enquiryForm.get('contactMobile').setValidators([Validators.required, Validators.pattern(this.utility.getNumberRegEx())]);
    } else {
      this.toggleAlternatecheck = false;
      this.fieldIds.splice(2, 3);
      idsArray.forEach(ele => {
        this.enquiryForm.get(ele).setErrors(null);
        this.enquiryForm.get(ele).clearValidators();
        // this.enquiryForm.get(ele).setValue('');
      });
    }
  }

  closeModal() {
    this.isModalOpen = false;
  }


  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-bottom-right';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = false;
    this.options.enableHtml = true;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    const event: Event = window.event;
    document.getElementsByClassName('overlay-container')[0].setAttribute('role', 'alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');
  }

  siteDetails() {
    //const selectedId = event.option.value;
    const selectedSiteReference = this.siteReferenceId.find(
      (subCategory) => subCategory[this.searchById]
    );
    this.selectedSiteName = selectedSiteReference
      ? selectedSiteReference.siteName
      : '';
    this.selectedRfnsa = selectedSiteReference
      ? selectedSiteReference.rfnsaSiteNo
      : '';
    this.selectedSiteRefId = selectedSiteReference
      ? selectedSiteReference.siteReferenceId
      : '';
  }

  ngOnDestroy() {
    this.resSubscribe.unsubscribe();
    this.subscription.unsubscribe();
    this.siteDetailsPopupData = '';
        this.siteId = '';
        this.siteAddress = '';
    this.common.Modalsubject.next('');
    this.siteDetailsPopupService.setDetails('');
  }
}