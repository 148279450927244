import { Injectable, ViewChild } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { AuthService } from 'tw-core-ui';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { LoadingServiceService } from './loading-service.service';
import { env } from 'process';


@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  options: GlobalConfig;
  private env: any = window['environment'];
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  constructor(private authService: AuthService, private loader: LoadingServiceService,
    private toastr: ToastrService,) {
    this.options = this.toastr.toastrConfig;
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(this.env.apiPoint)) {
      let loginKey = sessionStorage.getItem('uniqueId');
      const modifiedRequest = req.clone({
        setHeaders: {
          'Accept': '*/*',
          'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',
        },
        ...(loginKey && { params: (req.params ? req.params : new HttpParams()).set('LoginKey', loginKey) }),
      });
      return next.handle(modifiedRequest).pipe(catchError((err: any) => {
        this.toastr.clear();
        if (err instanceof HttpErrorResponse) {
          if (err.status == 401 || err.status == 500 || err.status == 503) {
            this.showToasterMessage(err.error.message || 'Something went wrong... ', '', this.options, 'error');
          }
        } else {
          // Handle non-HTTP errors
          //console.error('An error occurred:', err);
          // const msg= 'Something went wrong...'
          // this.showToasterMessage(msg, '', this.options, 'error');
        }
        // Re-throw the error to propagate it further
        return throwError(err);
      }));
    }
    return next.handle(req);
  }
  showToasterMessage(
    message: string,
    header: string,
    options: GlobalConfig,
    type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-bottom-right';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 10000;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(
      message,
      header === '' ? null : header,
      options,
      this.options.iconClasses[type]
    );
  }
}