import { ChangeDetectionStrategy, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SiteLocatorService } from './services/site-locator.service';
import { Subscription, combineLatest } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { MapStoreService } from './services/map-store.service';
import { LoadingServiceService } from '../services/loading-service.service';
import {
  FilterType,
  SiteLocation,
  SiteLocatorData,
  selectState,
} from './models/site-locator.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SiteLocatorPopupService } from './services/site-locator-popup.service';
import { Title } from '@angular/platform-browser';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MapComponent } from './components/map/map.component';
import { UtilityService } from '../services/utility.service';
@Component({
  selector: 'app-site-locator',
  templateUrl: './site-locator.component.html',
  styleUrls: ['./site-locator.component.less']
})
export class SiteLocatorComponent implements OnInit {
  @ViewChild('map') map: MapComponent | null = null;
  @ViewChild('siteDetails') siteDetailsPopupComponent: ElementRef;
 
  selectedSiteName: any;
  selectState: string = '';
  siteLocations: SiteLocation[];
  filteredData: SiteLocation[];
  searchForm: FormGroup;
  searchLabel: string = '';
  searchValue: string = 'wildSearch';
  showNoData: boolean = false;
  uniqueSuggestionId: any;
  mulitpleEntries = false;
  uniqueEntry = [];
  optionSelected = false;
  showMapSearch: boolean = false;
  searchEntity: string;
  selectedFilterWild :any;

  vm$ = combineLatest([this.siteLocatorPopupService.store$]).pipe(
    map(([siteLocatorPopUp]) => {
      this.handleFulleScrren(siteLocatorPopUp);
      return {
        siteLocatorPopUp,
      };
    })
  );

  constructor(
    private mapStoreService: MapStoreService,
    private siteLocatorService: SiteLocatorService,
    private fb: FormBuilder,
    private siteLocatorPopupService: SiteLocatorPopupService,
    private titleService: Title,
    private utility: UtilityService,
    private renderer: Renderer2
  ) {
    {
      this.titleService.setTitle('Amplitel - HomePage');
    }
  }

  selectFilter = [];

  public siteNameItems: any[] = [];
  selectStateValue = '';
  byDefaultPlaceholder: string = 'Search by address, name, or Amplitel site ref...';
  changePlaceholder: string = this.byDefaultPlaceholder;
  public aemLabelAuthoring: SiteLocatorData;
  markersSubscription: Subscription;
markersData: any[];

  availableState: selectState[] = [
    { label: 'Queensland', value: 'QLD' },
    { label: 'Tasmania', value: 'TAS' },
    { label: 'New South Wales', value: 'NSW' },
    { label: 'Victoria Australia', value: 'VIC' },
    { label: 'Western Australia', value: 'WA' },
    { label: 'South Australia', value: 'SA' },
    { label: 'Northern Territory', value: 'NT' },
    { label: 'Australian Capital Territory', value: 'ACT' },
  ];

  searchSubtype: selectState[] = [
    { label: 'Search', value: 'wildSearch' },
    { label: 'Site Address', value: 'address' },
    { label: 'Amplitel Site Reference', value: 'siteReferenceId' },
    { label: 'Site Name', value: 'siteName' },
    { label: 'RFNSA ID', value: 'rfnsaSiteNo' },
    { label: 'Pole ID', value: 'poleId' },
    { label: 'State', value: 'state' },
    { label: 'Lat & Long', value: 'lat-long' }
  ];

  filterType: FilterType[] = [
    {
      name: 'All Sites',
      iconUrl: '',
      selected: true,
      key: 'allSites',
      iconUrlActive: '',
    },
    {
      name: 'Towers',
      iconUrl: 'circle_grey.svg',
      selected: false,
      key: 'Towers',
      iconUrlActive: 'circle_aqua.svg',
    },
    {
      name: 'Poles',
      iconUrl: 'square_grey.svg',
      selected: false,
      key: 'Poles',
      iconUrlActive: 'square_aqua.svg',
    },
    {
      name: 'Rooftops',
      iconUrl: 'diamond_grey.svg',
      selected: false,
      key: 'Rooftops',
      iconUrlActive: 'diamond_aqua.svg',
    },
    {
      name: 'Exchanges',
      iconUrl: 'triangle_grey.svg',
      selected: false,
      key: 'Exchanges',
      iconUrlActive: 'triangle_aqua.svg',
    },
    {
      name: 'Landbanks/Sites In Development',
      iconUrl: 'cross_cross.svg',
      selected: false,
      key: 'Landbanks',
      iconUrlActive: 'cross_aqua.svg',
    },
  ];

  ngOnInit(): void {
  //  this.loader.setLoader(true);
    this.getLabels();
    this.searchForm = this.fb.group({
      searchType: [''],
      lat: ['', [Validators.required, Validators.pattern(this.utility.getLatitudeRegEx())]],
      long: ['', [Validators.required, Validators.pattern(this.utility.getLongitudeRegEx())]],
    });
    this.searchForm
      .get('searchType')
      .valueChanges.pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {
        if (
          (value?.length >= 2 && ['state', 'wildSearch'].includes(this.searchValue)) || (value?.length >= 3 && !['state', 'wildSearch'].includes(this.searchValue))
        ) {
          
            this.searchEntity = this.searchValue === 'wildSearch' ? this.checkPattern(value) : this.searchValue;
            this.suggestionOnFilter(value, true);
            this.showNoData = true;
            this.showMapSearch = true;
        } else {
          this.siteNameItems = [];
          this.showNoData = false;
          this.showMapSearch = false;
        }
      });
  }

  checkPattern(value){
    const matchedRegexType = this.validateInput(
      value,
      this.getStatusRegex()
    );
    if (matchedRegexType) {
      return matchedRegexType;
    } else {
      return 'address';
    } 
  }

  suggestionOnFilter(value: string, searchBydropDown: boolean) {
    if ((value?.length >= 2 && ['state', 'wildSearch'].includes(this.searchValue)) || (value?.length >= 3 && !['state', 'wildSearch'].includes(this.searchValue))){
      this.searchEntity = this.searchValue === 'wildSearch' ? this.checkPattern(value) : this.searchValue;
      this.showNoData = true;
      this.uniqueEntry = [];
      let filterResult =[];
      if(this.searchValue === 'wildSearch' && this.searchEntity === 'address'){
        this.filteredData.forEach((suggestion)=>{
          if(suggestion['address']?.toLowerCase().includes(value.toLowerCase())){
            filterResult.push({...suggestion, wildSearchType:'address'});
          }
          if(suggestion['siteName']?.toLowerCase().includes(value.toLowerCase())){
            filterResult.push({...suggestion, wildSearchType:'siteName'});
          }
         
        })
        this.selectedFilterWild = filterResult;
      }else{
         this.filteredData.forEach((suggestion) =>{
        if(suggestion[this.searchEntity]?.toLowerCase().includes(value.toLowerCase())){
          filterResult.push(suggestion);
        }
         });
      }
      filterResult.filter(() => {
          if (this.searchEntity === 'state' || this.searchEntity==='postcode') {
            this.mulitpleEntries = filterResult.length > 1 ? true : false;
          }
          else {
            this.mulitpleEntries = false;
          }
      });
      this.handledDataQuantity(filterResult, searchBydropDown);
    } else {
      this.showNoData = false;
    }
  }

  handledDataQuantity(filterResult, searchBydropDown: boolean) {
    if (!this.mulitpleEntries || (this.mulitpleEntries && !searchBydropDown) ) {
      this.siteNameItems = filterResult;
    } else {
      this.uniqueSuggestionId = Array.from(
        new Set(filterResult.map((suggestion:string) => suggestion[this.searchEntity]?.toLowerCase()))
      );
      this.uniqueEntry = this.uniqueSuggestionId.map((id) =>
        filterResult.find((suggestion) => suggestion[this.searchEntity]?.toLowerCase() === id)
      );
      this.siteNameItems = this.uniqueEntry;
    }
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    const input =  event.option.value;
    if(input){
      this.mapStoreService.patchStoreSelectedLocation(null);
      this.siteLocatorPopupService.patchStore(null);
      if(this.searchEntity === 'postcode' || this.searchEntity === 'state'){
        this.searchSiteData(input);
      }
      else{
        this.map.stateMarkers =[];
        this.mapStoreService.patchStoreSelectedLocation(
          input
        );
        input.displaySitePopup = true;
        this.siteLocatorPopupService.patchStore(input);
      this.selectedFilterWild = [];
      }
      this.optionSelected = true;
    }
  }

  displayFn(){
   return '';
  }

  getStatusRegex(): { [key: string]: RegExp } {
    return {
      postcode: /^\d{4}$/,
      rfnsaSiteNo: /^[0-9]{1,7}$/,
      state: /^(vic|sa|qld|tas|wa|nt|act|nsw)$/i,
      siteReferenceId: /^(vic|sa|qld|tas|wa|nt|act|nsw)[0-9]*$/i,
      address: /^[a-zA-Z0-9\s]+$/,
    };
  }

  validateInput(
    input: string,
    regexPatterns: { [key: string]: RegExp }
  ): string | null {
    for (const key in regexPatterns) {
      if (regexPatterns[key].test(input)) {
        return key;
      }
    }
    return null; 
  }

  getLabels() {
    this.mapStoreService.siteLocationsMerge$.subscribe((data) => {
      this.siteLocations = data;
      this.filteredData = data;
      this.updateMap();
    });
    return new Promise((resolve, reject) => {
      this.siteLocatorService
        .getSiteLocatorLabel()
        .then(() => {
          this.aemLabelAuthoring = this.siteLocatorService.siteLocatorDataObj;
          resolve(true);
        })
        .catch((err) => {
          reject(false);
        });
    });
  }

  searchSiteData(input: string) {
    if (input) {
      this.mapStoreService.patchStoreSelectedLocation(null);
      this.siteLocatorPopupService.patchStore(null);
        if (this.mulitpleEntries) {
          let selectedAddres : SiteLocation[]; 
            selectedAddres = this.filteredData.filter(
              (item) => item[this.searchEntity] == input
            );
          this.mapStoreService.patchStoreSelectedLocation(null);
          this.siteLocatorPopupService.patchStore(null);
          if(this.searchEntity === 'state'){
            input = this.availableState.filter(item=>item.value == input)[0].label;
          }
          this.map.stateFilterList(selectedAddres,input);
        } else {
          let selectedAddres = this.filteredData.filter(
              (item) => item[this.searchEntity] == input
            )[0];
          this.map.stateMarkers =[];
          this.mapStoreService.patchStoreSelectedLocation(selectedAddres);
          selectedAddres.displaySitePopup = true;
          this.siteLocatorPopupService.patchStore(selectedAddres);
          this.selectedFilterWild = [];
        }
    }
    this.optionSelected = true;
  }

  findStateLabel(input: string): string | undefined {
    const matchedState = this.availableState.find(
      (state) => state.value.toLowerCase() === input.toLowerCase()
    );
    return matchedState ? matchedState.label : undefined;
  }

  searchOnIconClick() {
    if(this.searchValue == 'address' || this.searchValue == 'state'){    
      const searchTerm: string = this.searchForm.get('searchType').value;
      this.suggestionOnFilter(searchTerm, false);
      let stateValFromDropDown  = searchTerm;
      if( this.searchValue == 'state'){
        stateValFromDropDown = this.availableState.filter(item=>item.value == searchTerm)[0].label;
      }
      this.searchSiteData(stateValFromDropDown);
      this.map.placeSearch(stateValFromDropDown);
    }
  }

  setActiveFilter(name): void {
    this.searchForm.get('searchType').setValue('');
    this.searchForm.reset();
    this.siteNameItems = [];
    if (name == 'allSites') {
      this.selectAll();
      return;
    }
    this.selectedItem(name);
    this.Highlighted(name);
    this.updateMap();
    this.markersSubscription = this.mapStoreService.markers$.subscribe((markers: any[]) => {
      this.filteredData = markers;
    });
  }

  updateMap() {
    this.mapStoreService.patchStoreSelectedLocation(null);
    this.siteLocatorPopupService.patchStore(null);
    this.mapStoreService.patchStore({
      filterData: true,
      filterOptions: this.selectFilter.length === 0 ? ['Towers','Poles','Rooftops','Exchanges','Landbanks'] : this.selectFilter,
    });
  }

  selectedItem(name: string) {
    if (this.selectFilter.includes(name)) {
      this.selectFilter = this.selectFilter.filter((data) => data != name);
    } else {
      this.selectFilter.push(name);
    }
    this.selectFilter = [...new Set(this.selectFilter)];
    if(this.selectFilter.length == 0){
      this.map.markerCluster?.clearMarkers();
    }
  }

  Highlighted(name: string) {
    this.filterType.forEach((el) => {
      el.selected = false;
      this.selectFilter.forEach((data) => {
        if (el.key == data) {
          el.selected = true;
        }
      });
     if(this.selectFilter.length === 0){
      this.filterType.filter(item=> item.key == 'allSites')[0].selected = true;
     }
    });
  }

  selectAll() {
    this.selectFilter = [];
    this.filterType.forEach((el, i) => {
      el.selected = i === 0;
    });
    this.filteredData = this.siteLocations;
    this.updateMap();
  }

  changeSearchType(type: selectState) {
    if (this.optionSelected) {
      this.updateMap();
    }
    this.uniqueSuggestionId = [];
    this.selectStateValue = '';
    this.searchLabel = type.label;
    this.searchValue = type.value;
    this.changePlaceholder = this.searchValue === 'wildSearch' ? `Search by address, name, or Amplitel site ref...` : ['state', 'siteReferenceId', 'poleId'].includes(this.searchValue) ? `Search by ${this.searchLabel}...` : `Search by Amplitel ${this.searchLabel}...`;
    this.searchForm.get('searchType').setValue('');
    this.optionSelected = false;
    this.showNoData = false;
    this.showMapSearch = false;
    this.searchEntity = '';
    this.searchForm.reset()
  }

  isSelected(type: selectState): boolean {
    return this.searchValue === type.value;
  }

  closeSitePopup(siteItem: SiteLocation) {
    siteItem.displaySitePopup = false;
    this.mapStoreService.patchStoreSelectedLocation(null);
    this.siteLocatorPopupService.patchStore(null);
  }

  searchPlaceOnMap() {
    const searchOnMap = this.searchForm.get('searchType').value;
    if (searchOnMap) {
      this.map.placeSearch(searchOnMap);
      this.siteLocatorPopupService.patchStore(null);
    }
  }

  searchLatLong(){
    if(this.searchForm.valid){
      this.mapStoreService.patchStoreSelectedLocation(null);
      this.siteLocatorPopupService.patchStore(null);
      this.map.stateMarkers =[];
      const lat = this.searchForm.get('lat').value;
    const long = this.searchForm.get('long').value;
    this.map.reverseGeocodeSearch(lat,long);
    }
  }

  handleFulleScrren(item:SiteLocation){
    if ((document as Document).fullscreen && item){
      setTimeout(()=>{
        this.renderer.appendChild( document.getElementById('map')?.firstChild,(this.siteDetailsPopupComponent as any).child.nativeElement)
        document.getElementsByClassName('dialog-child')[0].setAttribute('style','top:0px')
      },200)      
    }
  }
  @HostListener('document:fullscreenchange', ['$event'])
  onfullscreenchange(e) {
    if(this.siteDetailsPopupComponent){
      if ((document as Document).fullscreenElement) {
        this.renderer.appendChild( document.getElementById('map').firstChild,(this.siteDetailsPopupComponent as any).child.nativeElement);
        document.getElementsByClassName('dialog-child')[0].setAttribute('style','top:0px')
       } else {
        document.getElementsByClassName('dialog-child')[0].setAttribute('style','top:12.3rem')
        this.renderer.appendChild( document.getElementsByTagName('app-site-details-popup')[0],(this.siteDetailsPopupComponent as any).child.nativeElement);
      }
    }   
  }
}