
<ng-container *ngIf="vm$ | async as vm">
  <div class="content">
    <div class="container-fluid container-fluid-hide">
      <div class="row align-items-center search-wrap">
        <div class="col-lg-6" > 
          <form [formGroup]="searchForm" id="siteList" #siteList="ngForm">
            <div class="search-container">
              <div class="top-filter-container">
                <span class="filter-item"  *ngFor="let type of searchSubtype" (click)='changeSearchType(type)' (keypress)="changeSearchType(type)"   [ngClass]="{'selected-filter': isSelected(type)}" tabindex="0" role="tabpanel">{{ type.label }}</span>
              </div>
              <div class="d-inline-flex search-content">
                <ng-container *ngIf="searchValue !== 'lat-long'">
                <input 
                id="searchAmplitel"
                  matInput
                  [formControlName]="'searchType'"
                  type="text"
                  [placeholder]="changePlaceholder"
                  [matAutocomplete]="auto"
                  class="map-dropDown" 
                  aria-label="Search by address, name, or Amplitel site ref"
                  aria-controls="searchAmplitel"
                  (focus)="suggestionOnFilter(searchForm.get('searchType').value,true)"
               
                />
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onOptionSelected($event)">
                  <mat-option *ngIf="(showMapSearch || showNoData) && searchValue === 'address'">
                  <button type="button" class="btn btn-outline-info btn-map" (click)="searchPlaceOnMap($event)">Search on Map<mat-icon>place</mat-icon></button>
                  </mat-option>
                    <mat-option *ngIf="siteNameItems.length === 0 && showNoData" disabled class="no-data-option">
                      <span>{{ aemLabelAuthoring.searchBox.noDataFound }}</span>
                    </mat-option>
               
                  <ng-container *ngIf="searchEntity === 'postcode' || searchEntity === 'state'">
                  <mat-option *ngFor="let item of siteNameItems" [value]="item[searchEntity]">
                    {{ item[searchEntity] }}
                  </mat-option>
                </ng-container>
                  <ng-container *ngIf="!(searchEntity === 'postcode' || searchEntity === 'state')">
                    <mat-option  *ngFor="let item of siteNameItems" [value]="item">
                      {{ (searchValue === 'wildSearch' && searchEntity === 'address') ? item[item.wildSearchType] : item[searchEntity] }}
                    </mat-option>
                  </ng-container>
                  
                </mat-autocomplete>

                <button
                class="search-btn"
              > <img src="../../assets/images/telstra/svg/search_icon.svg" class="search-img" alt="search"></button>
                </ng-container>
                <ng-container *ngIf="searchValue === 'lat-long'">
                 <div class="row lat-long-wrap">
                    <div class="col-lat-lng">
                  <input 
                  id="lat"
                    matInput
                    [formControlName]="'lat'"
                    type="text"
                    placeholder="Type Latitude"
                    class="map-dropDown" 
                    aria-label="Latitude"
                  />
                  <mat-error *ngIf="searchForm.get('lat').dirty && searchForm.get('lat').hasError('required')">Latitude is required</mat-error>
                  <mat-error *ngIf="searchForm.get('lat').dirty && searchForm.get('lat').hasError('pattern')">Please add latitude in decimal format</mat-error>
                  </div>
                  <div class="col-lat-lng">
                  <input 
                  id="long"
                    matInput
                    [formControlName]="'long'"
                    type="text"
                    placeholder="Type Longitude"
                    class="map-dropDown" 
                    aria-label="Longitude"
                  />
                  <mat-error *ngIf="searchForm.get('long').dirty && searchForm.get('long').hasError('required')">Longitude is required</mat-error>
                  <mat-error *ngIf="searchForm.get('long').dirty && searchForm.get('long').hasError('pattern')">Please add longitude in decimal format</mat-error>
                </div>
                <div class="col-go">
                  <button type="submit" [class.disable]="searchForm.invalid" [disabled]="searchForm.invalid"
                  class="go-btn" (click)="searchLatLong()" tabindex="0" role="button"
                > Go</button>
              </div>
              </div>
                </ng-container>
                

              </div>
            </div>
          </form>
        </div>

        <div class="col-lg-6 filter-container">
          <section class="filter-wrap">
            <div tabindex="0" role="contentinfo">{{ aemLabelAuthoring?.filter.filterTitle }}</div>

            <div
              class="filter-list"
              *ngFor="let filter of filterType"
              [ngClass]="filter.selected ? 'active' : ''"
              (click)="setActiveFilter(filter.key)"
              (keypress)="setActiveFilter(filter.key)"
              tabindex="0"
              role="tabpanel"
            >
              <img
                class="filter-img"
                [src]="
                  filter.selected
                    ? '../../assets/images/telstra/svg/' + filter.iconUrlActive
                    : '../../assets/images/telstra/svg/' + filter.iconUrl
                "
                *ngIf="filter.iconUrl !== ''"
                [alt]="filter.name +' '+'site type filter'"
              />
              {{ filter.name }}
            </div>
          </section>
        </div>
      </div>
    </div>
    <div>
      <app-site-details-popup #siteDetails
        (closeButtonEvent)="closeSitePopup(vm.siteLocatorPopUp)"
        *ngIf="vm?.siteLocatorPopUp?.displaySitePopup"
        [siteDetailsData]="vm.siteLocatorPopUp"
      ></app-site-details-popup>
      <app-map #map></app-map>
    </div>
  </div>
</ng-container>