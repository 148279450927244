<!-- Modal -->
<div
  #child
  class="modal fade dialog-parent"
  [ngClass]="{ show: modalVisible }"
  id="exampleModals"
  tabindex="-1"
>
  <ng-container *ngIf="aemLabelAuthoring">
    <div class="modal-dialog dialog-child modal-dialog-scrollable" id="dialog-child">
      <div class="modal-content dialog-content">
        <div class="modal-header dialog-header">
          <h5 class="modal-title fw-bold" id="exampleModalLabel" tabindex="0" role="tabpanel">
            {{ aemLabelAuthoring.labels.siteDetails }}
          </h5>
          <button
            type="button"
            class="close-button fw-bolder"
            data-bs-dismiss="modal"
            aria-label="Close"
            (click)="close(true)"
          >
            &times;
          </button>
        </div>
        <div class="modal-body pb-0 pt-0" 
        [style.height]="siteDetailsData.structureDetails && siteDetailsData.structureDetails.length > 0 ? 'calc(100vh - 2rem)' : 'auto'">
          <div
            class="row"
            *ngIf="
              siteDetailsData.type === 'Landbanks' ||
              siteDetailsData.type === 'Rooftops' ||
              siteDetailsData.type === 'Towers' ||
              siteDetailsData.type === 'Exchanges'
            "
          >
            <div class="col-md-5 fw-bold">
              <p tabindex="0" role="tabpanel">{{ aemLabelAuthoring.labels.siteName }}:</p>
            </div>
            <div class="col-md-7" tabindex="0" role="tabpanel">
              <p>{{ siteDetailsData.siteName }}</p>
            </div>
          </div>

          <div
            class="row"
            *ngIf="
              (siteDetailsData.type === 'Landbanks' && siteDetailsData.siteReferenceId) ||
              siteDetailsData.type === 'Rooftops' ||
              siteDetailsData.type === 'Towers'
            "
          >
            <div class="col-md-5 fw-bold">
              <p tabindex="0" role="tabpanel">{{ aemLabelAuthoring.labels.siteId }}:</p>
            </div>
            <div class="col-md-7">
              <p tabindex="0" role="tabpanel">{{ siteDetailsData.siteReferenceId }}</p>
            </div>
          </div>

          <div class="row" *ngIf="siteDetailsData.type === 'Poles'">
            <div class="col-md-5 fw-bold">
              <p tabindex="0" role="tabpanel">{{ aemLabelAuthoring.labels.poleId }}:</p>
            </div>
            <div class="col-md-7">
              <p tabindex="0" role="tabpanel">{{ siteDetailsData.poleId }}</p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-5 fw-bold">
              <p tabindex="0" role="tabpanel">
                {{ aemLabelAuthoring.labels.lat }},
                {{ aemLabelAuthoring.labels.long }} :
              </p>
            </div>
            <div class="col-md-7">
              <p tabindex="0" role="tabpanel">
                {{ siteDetailsData.lat }}, {{ siteDetailsData.lng }}
              </p>
            </div>
          </div>
          <div class="row" *ngIf="
          (siteDetailsData.type === 'Landbanks' && siteDetailsData.siteReferenceId) ||
          siteDetailsData.type === 'Rooftops' ||
          siteDetailsData.type === 'Towers'
        ">
            <div class="col-md-5 fw-bold">
              <p tabindex="0" role="tabpanel">{{ aemLabelAuthoring.labels.siteType }}:</p>
            </div>
            <div class="col-md-7">
              <p tabindex="0" role="tabpanel">
                {{ siteDetailsData.type}}
              </p>
            </div>
          </div>
          <div class="row" *ngIf="
          (siteDetailsData.type === 'Landbanks' && siteDetailsData.siteReferenceId) ||
          siteDetailsData.type === 'Rooftops' ||
          siteDetailsData.type === 'Towers'
        ">
            <div class="col-md-5 fw-bold">
              <p tabindex="0" role="tabpanel">{{ aemLabelAuthoring.labels.rfnsaSiteNo }}:</p>
            </div>
            <div class="col-md-7">
              <p tabindex="0" role="tabpanel">
                {{ siteDetailsData.rfnsaSiteNo }}
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-5 fw-bold">
              <p tabindex="0" role="tabpanel">{{ aemLabelAuthoring.labels.address }}:</p>
            </div>
            <div class="col-md-7">
              <p tabindex="0" role="tabpanel">
                {{ siteDetailsData.address }}
              </p>
            </div>
          </div>

          <div class="row" *ngIf="siteDetailsData.type === 'Exchanges' || (siteDetailsData.type === 'Landbanks' && !siteDetailsData.siteReferenceId)">
            <div class="col-md-5 fw-bold">
              <p tabindex="0" role="tabpanel">{{ aemLabelAuthoring.labels.addressId }}:</p>
            </div>
            <div class="col-md-7">
              <p tabindex="0" role="tabpanel">
                {{ siteDetailsData.addressId }}
              </p>
            </div>
          </div>

          <div class="row"*ngIf="siteDetailsData.type === 'Poles'" >
            <div class="col-md-5 fw-bold">
              <p tabindex="0" role="tabpanel">{{ aemLabelAuthoring.labels.capacity }}:</p>
            </div>
            <div class="col-md-7">
              <p tabindex="0" role="tabpanel">
                {{ siteDetailsData.capacity? siteDetailsData.capacity :'-'}}
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <button 
            tabindex="0" role="button"
              type="button"
              class="btn btn-outline btn-style"
              data-bs-dismiss="modal" 
              (click)="redirectToEnquiry()"
            >
            Enquire About This Site
            </button>
            <button
              type="button"
              class="btn btn-outline btn-style"
              data-bs-dismiss="modal" 
              *ngIf="hasRolesForOrder && (
            siteDetailsData.type === 'Landbanks' ||
            siteDetailsData.type === 'Rooftops' ||
            siteDetailsData.type === 'Towers' ) && 
            !(siteDetailsData.siteTypeOAA === 'Yes' &&
            isColoOnly) && siteDetailsData.siteReferenceId"
          (click)="redirectToMyOrders()"
            >
            Create New Order
            </button>
            <div tabindex="0" role="button" class="download-btn" *ngIf="
            (siteDetailsData.type === 'Landbanks' && siteDetailsData.siteReferenceId)||
            siteDetailsData.type === 'Rooftops' ||
            siteDetailsData.type === 'Towers'
          " (click)="downloadReport(siteDetailsData.siteReferenceId)" (keypress)="downloadReport(siteDetailsData.siteReferenceId)">Download Preliminary Information Report</div>

          <div tabindex="0" role="button" class="download-btn" *ngIf="siteDetailsData.siteReferenceId" (click)="siteAccessInformation()" (keypress)="siteAccessInformation()">Site Access Information</div>
          </div>
          <div *ngIf="
          siteDetailsData.type === 'Landbanks' ||
          siteDetailsData.type === 'Rooftops' ||
          siteDetailsData.type === 'Towers'
        ">
          <div class="row pt-4 first-row" *ngFor="let structure of siteDetailsData.structureDetails; let i = index">
            <!-- <div class="col-md-5 fw-bold">
              <p>Structure {{ i + 1 }} :</p>
            </div> -->
            <div class="col-12 text-center digital-twin-btn-centailer">
              <button
                type="button"
                (click)="openIframe(siteDetailsData.siteReferenceId,structure.structureReferenceId)"
                class="btn btn-outline digital-twin-btn "
                [ngClass]="{
                  'btn-width': structure.digitalTwinAvailabilityStatus === 'Yes',
                  'twin-btn-disabled': structure.digitalTwinAvailabilityStatus === 'No'
                }"
                [disabled]="structure.digitalTwinAvailabilityStatus === 'No' ? true : false "
                tabindex="0" role="button"
              >
                Digital Twin ({{
                  structure.digitalTwinAvailabilityStatus === 'Yes' ? 'Available' : 'Not Available'
                }})
              </button>
            </div>
            <div class="col-md-5 fw-bold">
              <p tabindex="0" role="tabpanel">{{ aemLabelAuthoring.labels.structureId }}:</p>
            </div>
            <div class="col-md-7">
              <p tabindex="0" role="tabpanel">{{ structure.structureReferenceId }}</p>
            </div>
            <!-- <div class="col-md-5 fw-bold">
              <p>{{ aemLabelAuthoring.labels.structureName }}:</p>
            </div>
            <div class="col-md-7">
              <p>{{ structure.structureClassCode }}</p>
            </div>
            <div class="col-md-5 fw-bold">
              <p>{{ aemLabelAuthoring.labels.towerClassCode }}:</p>
            </div>
            <div class="col-md-7">
              <p>{{ structure.towerClassCode }}</p>
            </div> -->
            <div class="col-md-5 fw-bold">
              <p tabindex="0" role="tabpanel">{{ aemLabelAuthoring.labels.structureHeight }}:</p>
            </div>
            <div class="col-md-7">
              <p tabindex="0" role="tabpanel">{{ structure.structureHeight }}m</p>
            </div>

            <div class="col-md-5 fw-bold">
              <p tabindex="0" role="tabpanel">{{ aemLabelAuthoring.labels.structureType }}:</p>
            </div>
            <div class="col-md-7">
              <p tabindex="0" role="tabpanel">{{ structure?.structureType ? structure?.structureType :'-' }}</p>
            </div>

            
          </div>
          
        </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<!-- Site access information modal -->
<div *ngIf="isSiteAccessPopupShow">
  <app-site-access-information-popup (closePopupButtonEvent)="closePopup($event)" [siteReferenceId]="siteDetailsData.siteReferenceId" [siteAddress]="siteDetailsData.address"></app-site-access-information-popup>
</div>

<!--iframe Modal -->
<div
  *ngIf="iframeVisible"
  class="modal d-block opacity-iframe"
  id="iframeDialog"
  tabindex="-1"
  aria-labelledby="exampleModalIframeLabel"
  data-bs-backdrop="static"
>
  <div
    class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable justify-content-center iframe-zindex"
  >
    <div class="modal-content iframe-dialog-content">
      <div class="modal-header iframe-header pt-0 pb-0">
        <div class="col-xs-12 col-sm-12 col-md-7 pt-2 w-100" id="exampleModalIframeLabel">
          <button
            class="secondary-btn ticket-btn"
            [ngClass]="{ 'active-btn': activeButton === 'digital-twin' }"
            (click)="setActive('digital-twin')"
          >
            Digital Twin
          </button>
          <button
            class="secondary-btn ticket-btn"
            [ngClass]="{ 'active-btn': activeButton === 'los' }"
            (click)="setActive('los')"
          >
            Line of Sight
          </button>
          <button
            class="secondary-btn ticket-btn"
            [ngClass]="{ 'active-btn': activeButton === 'tower-view' }"
            (click)="setActive('tower-view')"
          >
            Tower Photo View
          </button>
          <button
            class="secondary-btn ticket-btn"
            [ngClass]="{ 'active-btn': activeButton === 'from-above' }"
            (click)="setActive('from-above')"
          >
            From Above
          </button>
          <button
            class="secondary-btn ticket-btn"
            [ngClass]="{ 'active-btn': activeButton === 'side-view' }"
            (click)="setActive('side-view')"
          >
            Side View
          </button>
        </div>
        <button
          type="button"
          class="fw-bolder close-button"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="close()"
        >
          &times;
        </button>
      </div>
      <div class="modal-body iframe-body pt-2">
        <div #myFrame>
        <div class="d-flex justify-content-end">
          <button *ngIf="isFirstLoadFullscreen || !isFullscreen" (click)="openFullscreen()" class="btn btn-sm fullscreen-btn">Full Screen</button>
          <button *ngIf="!isFirstLoadFullscreen && isFullscreen" (click)="exitFullscreen()" class="btn btn-sm fullscreen-btn">Exit Full Screen</button>
        </div>
        <div *ngIf="iframeVisible" class="ratio" style="--bs-aspect-ratio: 50%;">
          <div class="text-center" *ngIf="iframeLoading">
            <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
            </div>
          </div>
          <iframe  *ngIf="!iframeLoading" [src]="iframeUrl"  allowfullscreen title="Digital Twin iframe"></iframe>
        </div>
      </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show"></div>
</div>

<!-- iframe -->
