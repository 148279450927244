import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'tw-core-ui';
import { CommonService } from './services/common.service';
import { LoadingServiceService } from './services/loading-service.service';
import { TranslateService } from 'ng2-translate';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProfileService } from './services/profile.service';
import { cidnRegex } from './utlity/utlity';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  title = 'com-towcohub-frontend-spa';
  showLoading: Boolean = true;
  showCustomLoading: Boolean = true;
  showContentFlag: Boolean = false;
  showCustomValue = 0;
  isAcknoledged = true;
  status = [];
  private env: any = window['environment'];

  public primaryBusinessGroup: FormGroup;

  public defaultBusinessName;
  public profileBusinessNamesList: any = [];
  public companyData: any;
  public primaryBusinessName;
  public selectedBusiness;
  // profile: any = {};
  isOpenPopUp = false;
  isAllReady = false;
  roles = [];

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    // return false;
  }

  constructor(private fb: FormBuilder,
    // private profileService: ProfileService,
    private authService: AuthService,
    private router: Router,
    private loader: LoadingServiceService,
    private commonService: CommonService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    // this.isOpenPopUp = true;

    this.primaryBusinessGroup = this.fb.group({
      defaultBusinessNames: ['', [Validators.required]]
    })

    this.translate.addLangs(["en", "fr", "cn", "hi"]);
    this.translate.setDefaultLang("en");
    this.translate.use("en");
    this.loader.getLoader().subscribe(load => this.showLoading = load);
    this.loader.getCustomLoader().subscribe(load => this.showCustomLoading = load);
    this.loader.getCustomLoaderValue().subscribe(value => this.showCustomValue = value);
    this.showLoading = true;
    if (this.env.production) {
      if (window.location.href.search('error_description') > -1) {
        window.location.href = window['userManagerObj'].unauthorized_access_uri;
      } else {
        this.authService.completeAuthentication().then(() => {
          this.authService.getUserInfo().then((res) => {
            const CIDN_LIST = this.commonService.getroleDetails();
            this.roles = CIDN_LIST.roleList;
            if (this.roles.length == 0) {
              window.location.href = window['userManagerObj'].unauthorized_access_uri;
            } else {
              // this.showContentFlag = true;
              sessionStorage.setItem('contactName', res.given_name + ' ' + res.family_name);
              sessionStorage.setItem('firstName', res.given_name);
              sessionStorage.setItem('lastName', res.family_name);
              sessionStorage.setItem('email', res.username);
              sessionStorage.setItem('cidn', res.roles[0].value.split(":")[0]);
              this.showLoading = false;
              if (CIDN_LIST.cidnList && CIDN_LIST.cidnList.length) {
                this.commonService.getCustomerStatus({ 'cidnList': CIDN_LIST.cidnList.join() }).then(result => {
                  const customerData = result.data['Customer status'];
                  if (customerData.length > 0) {
                    for (let i = 0; i < customerData.length; i++) {
                      if (customerData[i].status.toLowerCase() === 'active') {
                        this.status.push(customerData[i].status);
                      }
                    }

                    if (sessionStorage.getItem('isLoginFlag')) {
                      this.navigate();
                    }
                    else {
                      this.isOpenPopUp = false;
                      this.showLoading = false;
                      this.showContentFlag = true;
                      this.loader.setCustomLoader(false);
                      this.loader.setLoader(false);
                      this.router.navigateByUrl('/terms-and-conditions');
                    }
                  }
                }).catch((err) => {
                  this.router.navigate(['AMS']);
                  this.showLoading = false;
                  this.showContentFlag = true;
                });
              }
            }
          });
        });
      }
    }
    else {
      if (sessionStorage.getItem('isLoginFlag')) {
        this.navigate();
      }
      else {
        this.isOpenPopUp = false;
        this.showLoading = false;
        this.showContentFlag = true;
        this.loader.setCustomLoader(false);
        this.loader.setLoader(false);
        this.router.navigateByUrl('/terms-and-conditions');
      }
    }
  }
  ngOnInit(): void {
    this.commonService.setTermsAndCondSubject.subscribe(result => {
      this.isOpenPopUp = false;
      this.showContentFlag = false;
      this.navigate();
    });
    // this.router.events.subscribe((router) => {    
    //   if (router instanceof NavigationEnd) {
    //     console.log('route',this.route.url)
    //     const details = JSON.parse(sessionStorage.getItem('selectedDetails'))
    //     this.commonService.updateValue(details);
    //   }
    // });
  }

  navigate() {
    this.loader.setLoader(true);
    // this.profile = this.profileService.getProfileDetails();
    this.commonService.setAdminAndBusiness();
    if (this.commonService.isAllBusiness) {
      this.isOpenPopUp = false;
      // this.commonService.updatePrimaryBusinessNameRoleForAmplitelAdmin();
      this.commonService.getAllBusinessNames()
      .then(res=>{
        this.navigateUrl();
        this.loader.setLoader(false);
        this.isAllReady = true;
      })
      .catch(err=>{});
    } else {
      // this.getAllBusinessNames();
      this.getBusinessDetails();
    }
  }

  async closeModal() {
    const details = { businessName: this.primaryBusinessName, cidn: this.selectedBusiness.companyId, 
      abn: this.companyData.abn, msoId: this.companyData.msoId, 
      associatedFor: this.selectedBusiness.associatedFor, roles: this.roles };
    this.commonService.updateValue(details);
    sessionStorage.setItem('selectedDetails', JSON.stringify(details));
    // this.router.navigateByUrl('/site-locator')
    this.isOpenPopUp = false;
    if (this.router.url === '/terms-and-conditions' || this.router.url === '/AMS' || this.router.url === '/acknowledge') {
      if(!sessionStorage.getItem('uniqueId')) {
        await this.commonService.userReport('login')
        .then(res => {})
        .catch(err => {});
      }
      this.showContentFlag = true;
      this.router.navigateByUrl('/site-locator');
    } else {
      this.navigateUrl();
    }
  }

  onBusinessNameChanged(newValue) {
    if (newValue) {
      this.primaryBusinessName = newValue;
      let businessDetails = this.companyData.associatedCompanies.filter(key => {
        return key.companyName.toLowerCase() === this.primaryBusinessName.toLowerCase();
      });
      this.selectedBusiness = businessDetails.length? businessDetails[0] : null;
    }
  }

  async navigateUrl() {
  if(!sessionStorage.getItem('uniqueId')) {
    await this.commonService.userReport('login')
      .then(res => {})
      .catch(err => {});
  }
  if (this.env.production) {
    if (this.authService.user.state) {
      if (this.status && this.status.length > 0) {
        if (sessionStorage.getItem('isLoginFlag') && this.authService.user.state === '/terms-and-conditions') {
          this.router.navigateByUrl('/')
        }else{
          this.router.navigate([this.authService.user.state]);
        }
        this.showContentFlag = true;
      } else {
        window.location.href = window['userManagerObj'].unauthorized_access_uri;
      }
    } else {
      if (this.status && this.status.length > 0) {
        this.showLoading = false;
        this.showContentFlag = true;
        if (sessionStorage.getItem('isLoginFlag') && this.router.url === '/terms-and-conditions') {
          this.router.navigateByUrl('/')
        }
      } else {
        window.location.href = window['userManagerObj'].unauthorized_access_uri;
      }
    }
  }
  else {
    this.showLoading = false;
    this.showContentFlag = true;
    if (sessionStorage.getItem('isLoginFlag') && this.router.url === '/terms-and-conditions') {
      this.router.navigateByUrl('/')
    }
  }
    //console.log(this.authService.user.state);
    //console.log(this.status, this.status.length);
  }

  // getAllBusinessNames() {
  //   console.log('navigate url selectedDetails', sessionStorage.getItem('selectedDetails'))
  //   console.log('navigate url getPrimaryValue', this.commonService.getPrimaryValue())
  //   console.log('window url', window.location.pathname) 
    
  //   const params = window.location.pathname.split('/');
  //   let selectedCdin = null;
  //   params.forEach(param => {
  //     if (param.match(cidnRegex)) {
  //       selectedCdin = param;
  //     }
  //   })
  //   if(!selectedCdin){
  //     const details = JSON.parse(sessionStorage.getItem('selectedDetails'));
  //     selectedCdin = details?.cidn;
  //   }
  //   this.commonService.getAllBusinessNames().then(res => {
  //     const allBusinessRoleData: any = res;
  //     this.allBusinessRoleData = res;
  //     if (allBusinessRoleData.length > 0) {
  //       (allBusinessRoleData || []).forEach(val => {
  //         if (this.profileBusinessNamesList.indexOf(val['Business name']) === -1) {
  //           this.profileBusinessNamesList.push(val['Business name']);
  //         }
  //       });
  //       this.profileBusinessNamesList.sort(this.itemSort);
  //     }
  //     if (this.profileBusinessNamesList.length == -1) {
  //       this.primaryBusinessName = this.profileBusinessNamesList[0];
  //       this.selectedBusiness = this.allBusinessRoleData.filter(key => {
  //         return key['Business name'].toLowerCase() === this.primaryBusinessName.toLowerCase();
  //       })
  //       // .map(key => {
  //       //   return key['CIDN'];
  //       // });
  //       // this.commonService.updateValue({ businessName: this.primaryBusinessName, cidn: this.selectedCIDN[0] });
  //       this.commonService.updateValue({ businessName: this.primaryBusinessName, cidn: this.selectedBusiness[0]['CIDN'], abn: this.selectedBusiness[0]['ABN'], msoId: this.selectedBusiness[0]['MSO_ID'] });
  //       // this.router.navigateByUrl('/site-access');
  //       this.isOpenPopUp = false;
  //       this.isAllReady = true;
  //       this.navigateUrl();
  //     } else {
  //       if (selectedCdin) {
  //         this.isOpenPopUp = false;
  //         this.isAllReady = true;
  //         this.selectedBusiness = this.allBusinessRoleData.filter(key => {
  //           return key['CIDN'].toLowerCase() === selectedCdin;
  //         })
  //         this.commonService.updateValue({ businessName: this.selectedBusiness[0]['Business name'] , cidn: this.selectedBusiness[0]['CIDN'], abn: this.selectedBusiness[0]['ABN'], msoId: this.selectedBusiness[0]['MSO_ID'] });
  //         this.navigateUrl();
  //       } else {
  //         this.isOpenPopUp = true;
  //         this.isAllReady = true;
  //       }
  //     }
  //     this.loader.setLoader(false);
  //   }).catch((err) => {
  //     console.log(err);
  //     // this.router.navigateByUrl('/error');
  //   });
  // }

  getBusinessDetails() {
    let selectedCidn = null;
    const params = window.location.pathname.split('/');    
    const details = JSON.parse(sessionStorage.getItem('selectedDetails'));
    params.forEach(param => {
      if (param.match(cidnRegex)) {
        selectedCidn = param;
      }
    })
    this.commonService.getBusinessDetails().then((res:any)=>{
      this.companyData = res;
      let companyTypes = res?.companyType?.toLowerCase();
      if(selectedCidn) {
        this.isOpenPopUp = false;
        this.isAllReady = true;
        if(companyTypes.includes("vendor")) {
          let entry = res?.associatedCompanies?.filter(x=>x.companyId==selectedCidn)[0];
          if(entry)
            this.commonService.updateValue({ businessName: entry?.companyName, cidn: entry?.companyId,abn: res?.abn,
           msoId: res?.msoId, associatedFor: entry?.associatedFor, roles: this.roles });
          else this.router.navigateByUrl('/error');
        }
        else {
          if(this.companyData.cidn==selectedCidn) 
            this.commonService.updateValue({ businessName: res?.companyName, cidn: res?.cidn,abn: res?.abn,
           msoId: res?.msoId, associatedFor: '', roles: this.roles });
          else this.router.navigateByUrl('/error');
        }
        this.navigateUrl();
      } else if(details?.cidn) {
        this.isOpenPopUp = false;
        this.isAllReady = true;
        this.commonService.updateValue({ businessName: details?.businessName, cidn: details?.cidn ,abn: details?.abn, 
          msoId: details?.msoId, associatedFor: details?.associatedFor, roles: this.roles });
        this.navigateUrl();
      } else {
        if(companyTypes.includes("vendor")) {
          if(res.associatedCompanies?.length){
            if(res.associatedCompanies?.length==1){
              this.isOpenPopUp = false;
              this.isAllReady = true;
              let entry = res.associatedCompanies[0];
              this.commonService.updateValue({ businessName: entry?.companyName, cidn: entry?.companyId,abn: res?.abn, 
                msoId: res?.msoId, associatedFor: entry?.associatedFor, roles: this.roles });
              this.navigateUrl();
            }
            else {
              res.associatedCompanies.map(x=>{
                this.profileBusinessNamesList.push(x.companyName);
              })
              this.profileBusinessNamesList.sort(this.itemSort);
              this.isOpenPopUp = true;
              this.isAllReady = true;
            }
          } else this.router.navigateByUrl('/error');
        }
        else {
          this.isOpenPopUp = false;
          this.isAllReady = true;
          this.commonService.updateValue({ businessName: res?.companyName, cidn: res?.cidn,abn: res?.abn, 
            msoId: res?.msoId, associatedFor: '', roles: this.roles });
          this.navigateUrl();
        } 
      }
    }).catch(err=>{});
    this.loader.setLoader(false);
  }

  itemSort(a, b) {
    const str1 = a.toLowerCase();
    const str2 = b.toLowerCase();
    let comparison = 0;
    if (str1 > str2) {
      comparison = 1;
    }
    else if (str1 < str2) {
      comparison = -1;
    }
    return comparison;
  }
}
