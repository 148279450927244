<div *ngIf="siteAccessInformationData" tabindex="-1" class="modal site-access-detail fade show" role="dialog" aria-labelledby="exampleModalLabel"
    style="display: block;">
    <div class="modal-dialog modal-full" #printContent [ngStyle]="{ '--modalHeight' :  modalHeight + 'px' }">
        <div class="modal-content">
            <div class="modal-header no-border pb-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="close()"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-6 pe-3">
                        <div class="mb-2">
                            <h5 class="modal-title">
                                Site Details
                            </h5>
                        </div>
                        <div class="site-detail-info" *ngFor="let item of siteInfo">
                            <h5 class="lable-title" role="tabpanel" tabindex="0">{{item.title}}:</h5>
                            <p class="lable-text" role="tabpanel" tabindex="0" [innerHTML]="item.value"></p>
                        </div>
                        <div class="mb-2">
                            <h5 class="modal-title">
                                Landlord and Access
                            </h5>
                        </div>
                        <div class="site-detail-info" [ngClass]="{'hazards-info-detail-full': item.flag === 'true'}" *ngFor="let item of setLandlordAndAccess">
                            <h5 class="lable-title" role="tabpanel" tabindex="0">{{item.title}}:</h5>
                            <p class="lable-text" role="tabpanel" tabindex="0" [innerHTML]="item.value"></p>
                        </div>
                    </div>
                    <div class="col-md-6 ps-3 border-left">
                        <div class="hazards-heading" role="tabpanel" tabindex="0">Hazards and Environmental</div>
                        <div class="hazards-info">
                            <div class="mb-2">
                                <div class="hazards-info-heading-line" role="tabpanel" tabindex="0">Site Hazard</div>
                                <div class="d-flex hazards-info-detail align-items-center font-medium">
                                    <div class="me-3 hazard-title" role="tabpanel" tabindex="0">Site Hazard:</div>
                                    <div *ngIf="siteAccessInformationData?.SiteHazard" class="hazards-info-detail-chip d-flex align-items-center"
                                        [ngClass]="siteAccessInformationData?.SiteHazard === 'None Identified' ? 'hazards-none-identified' : 'hazards-hazard-found'"
                                        role="tabpanel" tabindex="0">
                                        <img *ngIf="!(siteAccessInformationData?.SiteHazard === 'None Identified')"
                                            class="me-2" height="17" src="../../../../assets/images/harzard-icon.svg"
                                            alt="harzard icon">
                                        <span>{{siteAccessInformationData?.SiteHazard}}</span>
                                    </div>
                                </div>
                                <div *ngIf="siteAccessInformationData?.SiteHazardNotes"
                                    class="d-flex hazards-info-detail hazards-info-detail-full">
                                    <div class="hazard-title" role="tabpanel" tabindex="0">Site Hazard Notes:</div>
                                    <div class="hazard-text" role="tabpanel" tabindex="0" [innerHTML]="siteAccessInformationData?.SiteHazardNotes"></div>
                                </div>
                            </div>
                            <div class="mb-2">
                                <div class="hazards-info-heading" role="tabpanel" tabindex="0">Archaeological, Cultural
                                    and Heritage</div>
                                <div class="d-flex hazards-info-detail">
                                    <div class="me-3 hazard-title" role="tabpanel" tabindex="0">Archaeological, Cultural
                                        or Heritage zone:</div>
                                    <div role="tabpanel" tabindex="0">
                                        {{siteAccessInformationData?.ArchaeoCulturalHertiageZone}}</div>
                                </div>
                                <ng-container *ngIf="siteAccessInformationData?.ArchaeoCulturalHertiageZone === 'Yes'">
                                    <div *ngIf="siteAccessInformationData?.ArchaeoCulturalHertiageZoneRqmts"
                                        class="d-flex hazards-info-detail hazards-info-detail-full">
                                        <div class="hazard-title" role="tabpanel" tabindex="0">
                                            Archaeological/Cultural/Heritage Zone requirements:</div>
                                        <div class="hazard-text" role="tabpanel" tabindex="0" [innerHTML]="siteAccessInformationData?.ArchaeoCulturalHertiageZoneRqmts"></div>
                                    </div>
                                    <div *ngIf="siteAccessInformationData?.EnvironmentalAccessRequirements"
                                        class="d-flex hazards-info-detail hazards-info-detail-full">
                                        <div class="hazard-title" role="tabpanel" tabindex="0">Environmental Access
                                            Requirements:</div>
                                        <div class="hazard-text" role="tabpanel" tabindex="0" [innerHTML]="siteAccessInformationData?.EnvironmentalAccessRequirements"></div>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="mb-2">
                                <div class="hazards-info-heading" role="tabpanel" tabindex="0">Indigenous Community
                                </div>
                                <div class="d-flex hazards-info-detail">
                                    <div class="me-3 hazard-title" role="tabpanel" tabindex="0">Within Indigenous Land:
                                    </div>
                                    <div role="tabpanel" tabindex="0" [innerHTML]="siteAccessInformationData?.WithinIndigenousLand"></div>
                                </div>
                                <ng-container *ngIf="siteAccessInformationData?.WithinIndigenousLand === 'Yes'">
                                    <div *ngIf="siteAccessInformationData?.IndigenousLandownerContactName"
                                        class="d-flex hazards-info-detail">
                                        <div class="me-3 hazard-title" role="tabpanel" tabindex="0">Indigenous Community
                                            Contact Name:</div>
                                        <div role="tabpanel" tabindex="0" [innerHTML]="siteAccessInformationData?.IndigenousLandownerContactName"></div>
                                    </div>
                                    <div *ngIf="siteAccessInformationData?.IndigenousLandownerPhoneNumber"
                                        class="d-flex hazards-info-detail">
                                        <div class="me-3 hazard-title " role="tabpanel" tabindex="0">Indigenous
                                            Community Phone Number:</div>
                                        <div role="tabpanel" tabindex="0" [innerHTML]="siteAccessInformationData?.IndigenousLandownerPhoneNumber"></div>
                                    </div>
                                    <div *ngIf="siteAccessInformationData?.IndigenousLandownerNotes"
                                        class="d-flex hazards-info-detail hazards-info-detail-full">
                                        <div class="hazard-title" role="tabpanel" tabindex="0">Indigenous Community
                                            Notes:</div>
                                        <div class="hazard-text" role="tabpanel" tabindex="0" [innerHTML]="siteAccessInformationData?.IndigenousLandownerNotes"></div>
                                    </div>
                                </ng-container>
                            </div>
                            <div>
                                <div class="hazards-info-heading" role="tabpanel" tabindex="0">National Park</div>
                                <div class="d-flex hazards-info-detail">
                                    <div class="me-3 hazard-title" role="tabpanel" tabindex="0">Within National Park:
                                    </div>
                                    <div role="tabpanel" tabindex="0" [innerHTML]="siteAccessInformationData?.WithinNationalPark"></div>
                                </div>
                                <ng-container *ngIf="siteAccessInformationData?.WithinNationalPark === 'Yes'">
                                    <div *ngIf="siteAccessInformationData?.NationalParkName"
                                        class="d-flex hazards-info-detail">
                                        <div class="me-3 hazard-title" role="tabpanel" tabindex="0">National Park Name:
                                        </div>
                                        <div role="tabpanel" tabindex="0" [innerHTML]="siteAccessInformationData?.NationalParkName"></div>
                                    </div>
                                    <div *ngIf="siteAccessInformationData?.NationalParkContactName"
                                        class="d-flex hazards-info-detail">
                                        <div class="me-3 hazard-title " role="tabpanel" tabindex="0">National Park
                                            Contact Name:</div>
                                        <div role="tabpanel" tabindex="0" [innerHTML]="siteAccessInformationData?.NationalParkContactName"></div>
                                    </div>
                                    <div *ngIf="siteAccessInformationData?.NationalParkPhoneNumber"
                                        class="d-flex hazards-info-detail">
                                        <div class="me-3 hazard-title" role="tabpanel" tabindex="0">National Park Phone
                                            Number:</div>
                                        <div role="tabpanel" tabindex="0" [innerHTML]="siteAccessInformationData?.NationalParkPhoneNumber"></div>
                                    </div>
                                    <div *ngIf="siteAccessInformationData?.NationalParkNotes"
                                        class="d-flex hazards-info-detail hazards-info-detail-full">
                                        <div class="hazard-title " role="tabpanel" tabindex="0">National Park
                                            Notes:</div>
                                        <div class="hazard-text" role="tabpanel" tabindex="0" [innerHTML]="siteAccessInformationData?.NationalParkNotes"></div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" tabindex="0" class="btn next" (click)="redirectToSiteAccess()">Submit Site Access
                    Request</button>
            </div>
        </div>
    </div>
    <div class="modal-backdrop"></div>
</div>