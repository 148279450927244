import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  DoCheck,
  forwardRef,
  EventEmitter,
  OnChanges,
  ViewChild,
  ElementRef,
  OnInit,
  Renderer2,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { replace, includes, indexOf, some } from 'lodash';
export interface CustomDropDown {
  isDisabled: boolean;
  accessibleHeaderId: string;
}
@Component({
  selector: 'app-mutli-select',
  templateUrl: './mutli-select.component.html',
  styleUrls: ['./mutli-select.component.less']
})
export class MutliSelectComponent implements OnInit {


   // Can we use collective input object?
   @Input() labelName: string;
   @Input() placeholder: string;
   @Input() items: Array<string>;
   @Input() isRequired: boolean;
   @Input() selectItemType: string;
   @Input() noAutoComplete: boolean;
   @Input() model?: CustomDropDown = {
     isDisabled: false,
     accessibleHeaderId: ''
   };
 
   @Output() selectedValue = new EventEmitter<any>();
   @Output() clearTooltip = new EventEmitter<any>();
   @Output() resetPreVal = new EventEmitter<any>();
   @ViewChild('selectg', {static: true}) ngSelect: any;
   @ViewChild('selectDiv', {static: false}) selectDiv: ElementRef;
   noResultsFound: string;
   searchInputText: string;
   inputTextPatternFlag: boolean;
   selectField: string;
   oldFilteredOptions: any;
   completeText: any;
   isMobile = false;
   _value = '';
   itemList =['test1', 'test2', 'test3', 'test4'];
      private readonly innerIdSuffix = 'Placeholder';
   private readonly parentIdSuffix = 'Parent';
 
   constructor(private renderer: Renderer2, private el: ElementRef) {}
 
   simulateClick(event) {
     const el: HTMLElement = this.selectDiv.nativeElement.getElementsByClassName('ngx-select__toggle')[0] as HTMLElement;
     el.click();
   }
 
   // implement OnInit's `ngOnInit` method
   ngOnChanges() {
     this.noResultsFound = 'No ' + this.labelName + ' found!';
 
     // Accessibility: dynamic injection of required attributes
     const placeholderElement = this.ngSelect.mainElRef.nativeElement.querySelector('.ngx-select__placeholder span');
     if (placeholderElement && this.model.accessibleHeaderId !== '') {
       placeholderElement.setAttribute('id', this.model.accessibleHeaderId + this.innerIdSuffix);
     }
   }
   ngDoCheck() {
    // Accessibility: dynamic injection of required attributes
    if (this.ngSelect) {
     const selectedElement = this.ngSelect.mainElRef.nativeElement.querySelector('.ngx-select__selected-single span');
     if (selectedElement && this.model.accessibleHeaderId !== '') {
       const selectParentNode = this.ngSelect.mainElRef.nativeElement.querySelector('.ngx-select__selected');
       selectParentNode.setAttribute('id', this.model.accessibleHeaderId + this.parentIdSuffix);
       selectParentNode.setAttribute('aria-label', 'is selected');
       selectedElement.setAttribute('id', this.model.accessibleHeaderId + this.innerIdSuffix);
     }
 
     if (this.ngSelect.mainElRef.nativeElement.getElementsByTagName(
       'input'
     )[0]) {
       const textInput = this.ngSelect.mainElRef.nativeElement.getElementsByTagName(
         'input'
       )[0];
       const arrowImg = document.createElement('i');
       arrowImg.className = 'dropdown-toggle';
       arrowImg.classList.add('iconArrow');
       if (!(this.ngSelect.mainElRef.nativeElement.getElementsByClassName(
         'iconArrow'
       )[0])) {
         textInput.parentNode.insertBefore(arrowImg, textInput.nextSibling);
       }
     } else {
       const arrowImg = (this.ngSelect.mainElRef.nativeElement.getElementsByClassName(
         'iconArrow'
       )[0]);
       if (arrowImg) {
         arrowImg.parentNode.removeChild(arrowImg);
       }
     }
    }
   }
   ngOnInit(): void {
     // Accessibility: dynamic injection of required attributes & focus handler for header alike 'for' attribute
      if (this.ngSelect && this.model.accessibleHeaderId !== '') {
       document.querySelector('#' + this.model.accessibleHeaderId).addEventListener('click', () => {
         setTimeout(() => (this.ngSelect.mainElRef.nativeElement.focus(), 0));
       });
       this.ngSelect.mainElRef.nativeElement.setAttribute('role', 'combobox');
       this.ngSelect.mainElRef.nativeElement.setAttribute(
         'aria-labelledby', this.model.accessibleHeaderId + ' '
       + this.model.accessibleHeaderId + this.innerIdSuffix + ' ' + this.model.accessibleHeaderId + this.parentIdSuffix);
       this.ngSelect.mainElRef.nativeElement.setAttribute('aria-required', this.el.nativeElement.hasAttribute('required'));
     }
   }
   /* @HostListener('document:keydown', ['$event'])
   @HostListener('document:keyup', ['$event'])
   handleAccessibility(event: KeyboardEvent) {
 
     if (event instanceof KeyboardEvent) {
       if (event.code === 'ArrowUp' || event.code === 'ArrowDown') {
         this.ngSelect.keyCodeToNavigateNext = 'ArrowDown';
         this.ngSelect.keyCodeToNavigatePrevious = 'ArrowUp';
       } else {
 
         if (event.shiftKey) {
           this.ngSelect.keyCodeToNavigateNext = '';
           this.ngSelect.keyCodeToNavigatePrevious = 'Tab';
         } else {
           this.ngSelect.keyCodeToNavigateNext = 'Tab';
           this.ngSelect.keyCodeToNavigatePrevious = '';
         }
       }
     }
   } */
 
   public selected(value: any): void {
     this.propagateChange(value);
     if (this.selectedValue.observers.length > 0) {
       this.selectedValue.emit({
         value: {
           selectItemType: this.selectItemType,
           value: value,
           displayedValue: this.ngSelect.optionActive.text
         }
       });
     }
     if (
       this.ngSelect.mainElRef.nativeElement.getElementsByClassName(
         'ngx-select__clear'
       )[0]
     ) {
       this.ngSelect.mainElRef.nativeElement.getElementsByClassName(
         'ngx-select__clear'
       )[0].style =
         'color:#33a8a2';
     }
     this.ngSelect.mainElRef.nativeElement.focus();
   }
   public removed(value: any): void {
     this.propagateChange('');
     if (this.selectedValue.observers.length > 0) {
       this.selectedValue.emit({
         value: { selectItemType: this.selectItemType, value: '' }
       });
     }
   }
 
   propagateChange: any = () => { };
 
   writeValue(value: any) {
     if (value && value === '') {
       this.selectField = '';
       this._value = '';
     } else {
       this.selectField = value;
       this._value = value;
     }
   }
   registerOnChange(fn) {
     this.propagateChange = fn;
   }
   registerOnTouched(fn: () => void): void { }
   onChange(event) {
     this.propagateChange(event.target.value);
   }
   public typed(text: any): void {
     const checkSpecChar = some(
       ['@', '#', '$', '%', '&', '+', '=', '*', '!', '|', '^', '~'],
       el => includes(text, el)
     );
     if (!checkSpecChar) {
       this.oldFilteredOptions = this.ngSelect.optionsFiltered;
     }
     text = replace(text, '@', '');
     text = replace(text, '#', '');
     text = replace(text, '$', '');
     text = replace(text, '%', '');
     text = replace(text, '&', '');
     text = replace(text, '+', ' ');
     text = replace(text, '=', '');
     text = replace(text, '*', '');
     text = replace(text, '!', '');
     text = replace(text, '|', '');
     text = replace(text, '^', '');
     text = replace(text, '~', '');
     this.searchInputText = text;
     if (this.oldFilteredOptions && this.ngSelect.optionsFiltered.length === 0) {
       this.ngSelect.optionsFiltered = this.oldFilteredOptions;
     }
     if (this.ngSelect.optionsFiltered && this.ngSelect.optionsFiltered.length > 0) {
       this.ngSelect.keyCodeToOptionsSelect = 'Enter';
     } else {
       this.ngSelect.keyCodeToOptionsSelect = '';
     }
     this.ngSelect.subjSearchText._value = text;
     this.ngSelect.inputElRef.nativeElement.value = text;
     if (indexOf(this.items, this.searchInputText) >= 0) {
       this.selectField = text;
       this._value = text;
       this.selected(text);
     }
   }
   searchCallbackFn(search: string, source: any) {
     let text = search.trim();
     text = replace(text, /[  ]+[ ]?/, ' ');
     const src = source.text;
     const lowerTxt = text.toLowerCase();
     if (
       src.toLowerCase().indexOf(text) > -1 ||
       src.toUpperCase().indexOf(text) > -1 ||
       src.indexOf(text) > -1 ||
       src.toLowerCase().indexOf(lowerTxt) > -1
     ) {
       return true;
     } else {
       return false;
     }
   }
   onClose() {
     if (this.clearTooltip.observers.length > 0) {
       this.clearTooltip.emit({ state: false });
     }
   }
   getCustomisedText(text: any): any {
     let txt;
     text = text.changingThisBreaksApplicationSecurity;
     txt = text;
     txt = txt.replace(new RegExp('<strong>', 'g'), '');
     txt = txt.replace(new RegExp('</strong>', 'g'), '');
     this.completeText = txt;
     return txt;
   }
   onClick() {
     if (this.clearTooltip.observers.length > 0) {
       this.clearTooltip.emit({ state: true });
     }
 
     // add the custom atributes automationId and automationType.
     const ulEl = this.ngSelect.mainElRef.nativeElement.getElementsByClassName(
       'ngx-select__choices'
     );
     const liEl = this.ngSelect.mainElRef.nativeElement.getElementsByClassName(
       'ngx-select__item-group'
     );
     const searxhInputEl = this.ngSelect.mainElRef.nativeElement.getElementsByClassName(
       'ngx-select__search'
     );
     const automationId = this.labelName.replace(/\s+/g, '');
     if (ulEl && ulEl[0]) {
       ulEl[0].setAttribute('automationType', 'ul' + automationId);
       ulEl[0].setAttribute('automationId', 'unorderedList');
       const optionsLen = liEl.length;
       if (liEl) {
         for (let i = 0; i < optionsLen; i++) {
           liEl[i].setAttribute('automationId', 'li' + automationId);
           liEl[i].setAttribute('automationType', 'list');
         }
       }
     }
     if (searxhInputEl && searxhInputEl[0]) {
       searxhInputEl[0].setAttribute('automationType', 'textbox');
       searxhInputEl[0].setAttribute('automationId', 'txtbxSearchInput');
     }
   }
   onBlur(event: any) {
     if (
       this.selectItemType &&
       (this.selectItemType === 'aEndDC' || this.selectItemType === 'bEndDC') &&
       this.resetPreVal.observers.length > 0) {
       this.resetPreVal.emit({
         value: { selectItemType: this.selectItemType, value: this.selectField }
       });
     }
   }
   selectionChanged(item:any):void{
}
  searchChanged(item:any):void{
  }
 }
 