import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { concatMap, delay, map, retryWhen, scan, share, shareReplay, switchMap, takeWhile } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from 'tw-core-ui';
import { SiteLocation, SiteLocationRespose, SiteLocationStore, SiteLocatorData } from '../models/site-locator.model';
import { throwError as observableThrowError } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class SiteLocatorService {
  public env: any = environment;

  retryCount = 1;
  retryWaitMilliSeconds = 100;


  private apiURL = {
    siteDetailsURL: 'site-details/sites',
    exchangeDetailsURL: 'site-details/exchanges',
    polesDetailsURL: 'site-details/poles'
  };

  locations: SiteLocationStore = {
    data: []
  }
  mapToken$ = of("AIzaSyDijKQxKRM9nlIGsrWd59hiZDPdm-5xsMU").pipe(delay(100));
  siteLocations$ = of(this.locations).pipe(delay(100));
  public siteLocatorDataObj: SiteLocatorData;

  constructor(private http: HttpClient, private authService: AuthService,private commonService:CommonService) {
  }

  private siteLocator = {
    siteLocatorLabels: 'siteLocator',
  };

  public getSiteLocatorLabel() {
    return new Promise((resolve, reject) => {
      if (!this.siteLocatorDataObj) {
        this.http.get<any>(this.env.aemEndPoint + this.siteLocator.siteLocatorLabels)
          .subscribe(
            res => {
              this.siteLocatorDataObj = res.data.content;
              resolve(this.siteLocatorDataObj);
            },
            err => {
              this.siteLocatorDataObj = null;
              reject(err);
              return observableThrowError(err || 'Server error');
            }
          );
      } else {
        resolve(this.siteLocatorDataObj);
      }
    });
  }
  getSiteDetails(limit: number, offset = 0): Observable<SiteLocation[]> {
    const siteDetailsUrl = this.env.apiPoint + this.apiURL['siteDetailsURL'];
    const params = new HttpParams().set("limit", limit + '').set("offset", offset + '');
    return this.http.get<SiteLocationRespose>(siteDetailsUrl, { headers:this.getCidnHeaders(), params })
      .pipe(
        retryWhen(error => 
          error.pipe(
            concatMap((error, count) => {
              //it will retry 1 time if the error code is 500,503
              if (count < this.retryCount && (error.status == 500 || error.status == 503)) {
                return of(error);
              }
              return throwError(error);
            }),
            delay(this.retryWaitMilliSeconds)
          )
        ),
        map((res: SiteLocationRespose) => {
          return res.data.siteDetails
        }),
        shareReplay(1)
      )
  }
  getPolesDetails(limit: number, offset = 0): Observable<SiteLocation[]> {
    const polesDetailsURL = this.env.apiPoint + this.apiURL['polesDetailsURL'];
    const params = new HttpParams().set("limit", limit + '').set("offset", offset + '');
    return this.http.get<SiteLocationRespose>(polesDetailsURL, { headers:this.getCidnHeaders(), params })
      .pipe(
        retryWhen(error => 
          error.pipe(
            concatMap((error, count) => {
              if (count < this.retryCount && (error.status == 500 || error.status == 503)) {
                return of(error);
              }
              return throwError(error);
            }),
            delay(this.retryWaitMilliSeconds)
          )
        ),
        map((res: SiteLocationRespose) => {
          return res.data.polesData
        })
      )
  }
  getExchangeDetails(limit: number, offset = 0): Observable<SiteLocation[]> {
    const exchangeDetailsURL = this.env.apiPoint + this.apiURL['exchangeDetailsURL'];
    const params = new HttpParams().set("limit", limit + '').set("offset", offset + '');   
    return this.http.get<SiteLocationRespose>(exchangeDetailsURL, { headers:this.getCidnHeaders(), params })
      .pipe(
        retryWhen(error => 
          error.pipe(
            concatMap((error, count) => {
              if (count < this.retryCount && (error.status == 500 || error.status == 503)) {
                return of(error);
              }
              return throwError(error);
            }),
            delay(this.retryWaitMilliSeconds)
          )
        ),
        map((res: SiteLocationRespose) => {
          return res.data.exchangesData
        })
      )
  }

  getCidnHeaders():HttpHeaders{
    let cidn = this.commonService.getPrimaryValue().cidn;
    if(!cidn) cidn=this.commonService.getroleDetails().adminCidns.length?this.commonService.getroleDetails().adminCidns[0]:null;
    return new HttpHeaders().append('cidn',cidn);
  }
}

